import { InfoIcon } from 'assets/svgs';
import styled from 'styled-components';
import { colors } from '../styles/common';

export const Tooltip = ({ content }: { content: string }) => {
  return (
    <Wrapper>
      <InfoIcon />
      <p>{content}</p>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    position: relative;
    top: 3px;
  }

  svg:hover + p {
    display: block;
  }

  p {
    position: absolute;
    top: 110%;
    left: 0;
    width: 300px;
    background-color: ${colors.GRAYE};
    font-size: 13px;
    text-align: left;
    color: ${colors.BLACK};
    padding: 8px;
    display: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
    z-index: 99;
    line-height: 1.5;
  }
`;
