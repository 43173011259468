export const colors = {
  // Grayscale
  WHITE: '#ffffff',
  BLACK: '#000000',
  GRAYA: '#aaaaaa',
  GRAYC: '#cccccc',
  GRAYD: '#dddddd',
  GRAY2: '#222222',
  GRAY4: '#444444',
  GRAY5: '#555555',
  GRAY7: '#777777',
  GRAY78: '#787878',
  GRAY9: '#999999',
  GRAYE: '#eeeeee',
  GRAYF2: '#f2f2f2',
  GRAYF8: '#f8f8f8',
  BEIGE: '#dee0d9',

  PRIMARY: '#16325f',
  SECONDARY: '#2e9fde',
  TERTIARY: '#bdd7e2',
  YELLOW: '#ffda58',
  RED: '#d46968',

  BLUE: '#016bdf',
  BABY_BLUE: '#7fb5eb',
  INDIGO: '#14335d',

  SHADOW: '0px 0px 5px #cccccc',
};

export const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: 'rgba(0,0,0,0.75)',
  },
};

interface IFlexbox {
  fd?: string;
  gap?: number;
  jc?: string;
  ai?: string;
}

interface IGridbox {
  n?: number;
  gap?: number;
}

interface ITextEllipsis {
  n?: number;
}

export const flexbox = ({ fd = 'row', gap = 0, ai = 'center', jc = 'center' }: IFlexbox) => {
  return `
  display: flex;
  flex-direction: ${fd};
  gap: ${gap}px;
  align-items: ${ai};
  justify-content: ${jc};
  `;
};

export const gridbox = ({ n = 1, gap = 0 }: IGridbox) => {
  return `
  display: grid;
  grid-template-columns: repeat(${n}, 1fr);
  gap: ${gap}px;
  `;
};

export const textEllipsis = ({ n = 1 }: ITextEllipsis) => {
  return `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${n};
  -webkit-box-orient: vertical;
  padding-top: 1px;
  line-height: 1.3;
  `;
};

export const commonLabelStyle = () => {
  return `
    display: block;
    color: ${colors.PRIMARY};
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    line-height: 14px;
    margin-bottom: 8px;`;
};

export const commonInputStyle = () => {
  return `
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${colors.GRAYA};
  line-height: 40px;
  padding: 0 10px;
  text-align: left;

  &::placeholder {
    color: ${colors.GRAYA};
    text-align: left;
  }`;
};
