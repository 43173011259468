import { DetailedHTMLProps, ReactElement, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { colors, commonInputStyle, commonLabelStyle } from 'styles/common';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  unit?: string;
  children?: ReactElement;
}

const StyledInput = ({ type = 'text', label, children, unit, ...props }: IProps) => {
  return (
    <Wrapper>
      {label && <label htmlFor={label}>{label}</label>}
      {children}
      <input id={label} type={type} {...props} />
      {unit && <Unit>{unit}</Unit>}
    </Wrapper>
  );
};

export const Label = styled.p`
  ${commonLabelStyle};
  text-align: left;
`;

const Unit = styled.span`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.GRAY2};
  font-size: 14px;
`;

const Wrapper = styled.div`
  //width: 100%;
  position: relative;

  label {
    ${commonLabelStyle};
    text-align: left;
  }

  input {
    ${commonInputStyle()};
  }

  button {
    position: absolute;
    top: 33px;
    right: 10px;
    border: none;
    background: none;
  }
`;

export default StyledInput;
