import styled, { keyframes } from 'styled-components';

import { colors } from 'styles/common';
import { SpinnerIcon } from 'assets/svgs';

const Loading = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <div>
          <SpinnerIcon />
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

const spin = keyframes`
	0%{
    transform: rotate(0deg);
    }
    100%{
    	transform: rotate(359deg);
    }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: transparent;
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 80px;
      height: 80px;
      stroke: ${colors.PRIMARY};
      animation: ${spin} 1s linear infinite;
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.PRIMARY};
    font-size: 13px;
  }
`;

export default Loading;
