import { Suspense, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { plainInstance } from 'hooks/worker/axios';
import Loading from 'components/Loading';
import { TPageType } from 'types/enum';

import Side from 'pages/IFrameWidget/Side';
import Top from 'pages/IFrameWidget/Top';
import Center from 'pages/IFrameWidget/Center';

const WidgetDataFetch = ({
  title,
  type,
  template,
  queryString,
  view,
}: {
  title: string;
  type: TPageType<'widget'>;
  template: string;
  queryString: string;
  view: number;
}) => {
  const [currentData, setCurrentData] = useState<any[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [totalPages, setTotalPages] = useState<number>();

  const getData = async ({ qryType, qryString, pageParam = 1 }: { qryType: string; qryString: string; pageParam: number }) => {
    const res = await plainInstance.get(`/widget/config/preview`, { params: { type: qryType, qryString, page: pageParam } });
    return res.data;
  };

  const { data, fetchNextPage, isError, isLoading, isFetching } = useInfiniteQuery(
    ['widgetPreview', type, template, queryString],
    ({ pageParam = 1 }) => getData({ qryType: type, qryString: queryString, pageParam }),
    {
      suspense: true,
      getPreviousPageParam: () => {
        return currentPage > 1;
      },
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage) return undefined;
        // if (Math.ceil(lastPage.count / view) === allPages.length) return undefined;
        if (lastPage.length < 1) return undefined;
        if (!lastPage.count) return allPages.length + 1;
        if (allPages.length * Number(view) < lastPage.count) return allPages.length + 1;
        return undefined;
      },
      enabled: !!type && !!template,
    }
  );

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    fetchNextPage();
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (data.pages[currentPage - 1]) {
      setCurrentData(data.pages[currentPage - 1].list);
    }
  }, [currentPage, data]);

  useEffect(() => {
    setTotalPages(Math.ceil(data.pages[data.pages.length - 1].count / view));
  }, [data, view]);

  if (!currentData || isLoading) return <Loading />;
  if (isError) return <h3>잘못된 데이터 입니다.</h3>;

  return (
    <Suspense fallback={<Loading />}>
      {template === 'top' && <Top title={title} type={type} data={data.pages} />}
      {template === 'side' && (
        <Side
          title={title}
          type={type}
          data={data.pages}
          handlePrev={handlePrev}
          handleNext={handleNext}
          hasPreviousPage={currentPage > 1}
          hasNextPage={type === 'news' ? true : currentPage < totalPages}
          isFetching={isFetching}
          currentPage={currentPage}
          view={view}
        />
      )}
      {template === 'center' && (
        <Center
          title={title}
          type={type}
          data={data.pages}
          handlePrev={handlePrev}
          hasPreviousPage={currentPage > 1}
          handleNext={handleNext}
          hasNextPage={type === 'news' ? true : currentPage < totalPages}
          isFetching={isFetching}
          currentPage={currentPage}
          view={view}
        />
      )}
    </Suspense>
  );
};

export default WidgetDataFetch;
