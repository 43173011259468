/* eslint-disable */
// @ts-nocheck
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import StaticAxios from 'axios';
import LRU from 'lru-cache';

const actions = {
  REQUEST_START: 'REQUEST_START',
  REQUEST_END: 'REQUEST_END',
};

const useAxios = makeUseAxios();
export const { configure } = useAxios;

export function makeUseAxios(configurationOptions) {
  let cache;
  let axiosInstance;

  function resetConfigure() {
    cache = new LRU();
    axiosInstance = StaticAxios;
  }

  function configure(options = {}) {
    if (options.axios !== undefined) {
      axiosInstance = options.axios;
    }

    if (options.cache !== undefined) {
      cache = options.cache;
    }
  }

  resetConfigure();
  configure(configurationOptions);

  return Object.assign(useAxios, {
    configure,
  });

  async function executeRequest(config, dispatch) {
    try {
      dispatch({ type: actions.REQUEST_START });

      const response = await axiosInstance(config);

      tryStoreInCache(config, response);

      dispatch({ type: actions.REQUEST_END, payload: response });

      return response;
    } catch (err) {
      if (!StaticAxios.isCancel(err)) {
        dispatch({ type: actions.REQUEST_END, payload: err, error: true });
      }

      throw err;
    }
  }

  async function request(config, options, dispatch) {
    return tryGetFromCache(config, options, dispatch) || executeRequest(config, dispatch);
  }

  function useAxios(config, options) {
    config = useMemo(() => configToObject(config), [JSON.stringify(config)]);

    options = useMemo(() => ({ manual: false, useCache: true, ...options }), [JSON.stringify(options)]);

    const cancelSourceRef = useRef();

    const [state, dispatch] = useReducer(reducer, createInitialState(config, options));

    if (typeof window === 'undefined' && !options.manual) {
      useAxios.__ssrPromises.push(axiosInstance(config));
    }

    const cancelOutstandingRequest = useCallback(() => {
      if (cancelSourceRef.current) {
        cancelSourceRef.current.cancel();
      }
    }, []);

    const withCancelToken = useCallback(
      (config) => {
        cancelOutstandingRequest();

        cancelSourceRef.current = StaticAxios.CancelToken.source();

        config.cancelToken = cancelSourceRef.current.token;

        return config;
      },
      [cancelOutstandingRequest]
    );

    useEffect(() => {
      if (!options.manual) {
        request(withCancelToken(config), options, dispatch).catch(() => {});
      }

      return cancelOutstandingRequest;
    }, [config, options, withCancelToken, cancelOutstandingRequest]);

    const refetch = useCallback(
      (configOverride, options) => {
        configOverride = configToObject(configOverride);

        return request(
          withCancelToken({
            ...config,
            ...(isReactEvent(configOverride) ? null : configOverride),
          }),
          { useCache: false, ...options },
          dispatch
        );
      },
      [config, withCancelToken]
    );

    return [state, refetch];
  }
}
