import styled, { keyframes } from 'styled-components';

import { colors } from 'styles/common';

const Loading2 = () => {
  return (
    <InnerWrapper>
      <SpanWrapper className='loader' />
    </InnerWrapper>
  );
};

const bblFadInOut = keyframes`
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SpanWrapper = styled.span`
  &,
  &:before,
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    animation-fill-mode: both;
    animation: ${bblFadInOut} 1.8s infinite ease-in-out;
  }
  & {
    color: ${colors.BABY_BLUE};
    font-size: 8px;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }

  &:before {
    left: -16px;
    animation-delay: -0.32s;
  }
  &:after {
    left: 16px;
  }
`;

export default Loading2;
