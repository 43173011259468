import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { plainInstance } from 'hooks/worker/axios';

import Side from './Side';
import Top from './Top';
import Center from './Center';

interface ISearchParams {
  id: string;
  cpp: number;
  pageParam: number;
}

const DataFetch = ({
  config: { title, type, template },
  uuid,
  view,
}: {
  config: { title: string; type: string; template: string };
  uuid: string;
  view: number;
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getData = async ({ id, cpp = 1, pageParam = 1 }: ISearchParams) => {
    const res = await plainInstance.get(`/widget/search/${id}?rowsPerPage=${cpp}&page=${pageParam}`);
    const {
      data: {
        data: { list, count },
        success,
      },
    } = res;
    if (success) {
      if (count) return { pages: Math.ceil(count / view), cursor: pageParam, list };
      return { cursor: pageParam, list };
    }
    return {};
  };

  const { data, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery(
    ['page', uuid],
    ({ pageParam = 1 }) => getData({ id: uuid, cpp: Number(view), pageParam }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        if (!lastPage.pages) return lastPage.cursor + 1;
        if (lastPage.pages && lastPage.cursor + 1 < lastPage.pages) return lastPage.cursor + 1;
        return undefined;
      },
    }
  );

  const handlePrev = () => {
    if (!isFetching) {
      setCurrentPage((prev) => prev - view);
    }
  };

  const handleNext = () => {
    if (!isFetching)
      fetchNextPage().then((r) => {
        if (r.status === 'success') setCurrentPage((prev) => prev + view);
      });
  };

  if (!data) return undefined;

  return (
    <>
      {template === 'top' && <Top title={title} type={type} data={data.pages} />}
      {template === 'side' && (
        <Side
          title={title}
          type={type}
          data={data.pages}
          handlePrev={handlePrev}
          handleNext={handleNext}
          hasPreviousPage={currentPage > 1}
          // eslint-disable-next-line no-nested-ternary
          hasNextPage={type === 'news' ? !isFetching : isFetching ? false : hasNextPage}
          isFetching={isFetching}
          currentPage={currentPage}
          view={view}
        />
      )}
      {template === 'center' && (
        <Center
          title={title}
          type={type}
          data={data.pages}
          handlePrev={handlePrev}
          hasPreviousPage={currentPage > 1}
          handleNext={handleNext}
          // eslint-disable-next-line no-nested-ternary
          hasNextPage={type === 'news' ? !isFetching : isFetching ? false : hasNextPage}
          isFetching={isFetching}
          currentPage={currentPage}
          view={view}
        />
      )}
    </>
  );
};

export default DataFetch;
