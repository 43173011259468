import { CaretDownIcon } from 'assets/svgs';
import { cloneElement, Dispatch, MouseEvent, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';

import { colors, commonInputStyle, commonLabelStyle } from 'styles/common';

interface IProps {
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  setWindowValue?: Dispatch<SetStateAction<string>>;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  children?: string;
}

export const Option = ({ value, setValue, setWindowValue, setOpen, children }: IProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setWindowValue(e.currentTarget.dataset.window);
    setValue(e.currentTarget.value);
    setOpen(false);
  };
  return (
    <button type='button' value={value} data-window={children} onClick={handleClick}>
      {children}
    </button>
  );
};

export const StyledSelect = ({
  label,
  name,
  value,
  setValue,
  children,
}: {
  label?: string;
  name: string;
  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  children: ReactElement[];
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useClickAway(dropdownRef, () => setOpen(false));

  const [windowValue, setWindowValue] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (value) {
      const find = children.find((item) => item.props.value === value);
      setWindowValue(find.props.children);
    }
  }, [children, value]);

  return (
    <Wrapper>
      <input
        type='text'
        className='hide'
        name={name}
        value={value || ''}
        required={value && value.length < 2}
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
      />
      <div ref={dropdownRef}>
        {label && <Label>{label}</Label>}
        <Button type='button' onClick={handleClick}>
          {windowValue || '선택해주세요'}
          <CaretDownIcon />
        </Button>
        <Ul open={open ? 'open' : 'close'}>
          {children.map((item, index) => {
            const key = `tab-${index}`;
            const babyWithProps = cloneElement(item, { setValue, setWindowValue, setOpen });
            return <li key={key}>{babyWithProps}</li>;
          })}
        </Ul>
      </div>
    </Wrapper>
  );
};
const Label = styled.p`
  ${commonLabelStyle};
`;

const Wrapper = styled.div`
  //width: 100%;
  min-width: 320px;
  position: relative;

  .hide {
    position: absolute;
    left: 0;
    top: 40px;
    width: 0;
    min-width: 0 !important;
    height: 0;
    z-index: -99999;
  }
`;

const Button = styled.button<{ readOnly?: boolean }>`
  ${commonInputStyle()};
  background: ${colors.WHITE};
  position: relative;
  cursor: ${({ readOnly }) => {
    if (readOnly) return 'not-allowed';
    return 'pointer';
  }};

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    fill: ${colors.PRIMARY};
  }
`;

const Ul = styled.ul<{ open?: string }>`
  position: absolute;
  z-index: 10;
  top: 65px;
  width: 100%;
  border-radius: 4px;
  background: ${colors.GRAYF2};
  border: 1px solid ${colors.GRAYC};

  display: ${({ open }) => {
    if (open === 'open') return 'block';
    return 'none';
  }};

  button {
    border: none;
    background: transparent;
    line-height: 40px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background: #dfe1f6;
    }
  }
`;

StyledSelect.Option = Option;
