export type TSubmit = '등록' | '수정';
export type TFormType = 'embed' | 'widget';
export type TWidget = 'news' | 'business' | 'service' | 'bill';
export type TEmbed = 'bill' | 'asmman' | 'lawnum';
export type TWidgetTemplate = 'side' | 'center' | 'top';
export type TEmbedTemplate = 'template1' | 'template2';

export const FormName: { [key: string]: string } = {
  embed: '임베드',
  widget: '위젯',
};

export const WidgetName: { [key: string]: string } = {
  news: '뉴스',
  business: '지원사업',
  service: '정책서비스',
  bill: '입법정보',
};

export const EmbedName: { [key: string]: string } = {
  bill: '법안(검색)',
  asmman: '최대 발의 의원',
  lawnum: '법안(특정)',
  news: '뉴스',
};

export const TemplateName: { [key: string]: string } = {
  side: '측면',
  center: '중앙',
  top: '상단',
  template1: 'template1',
  template2: 'template2',
};

export const isTWidgetOrTEmbed = (value: any): value is TWidget | TEmbed => {
  return value === 'news' || value === 'business' || value === 'service' || value === 'bill' || value === 'asmman' || value === 'lawnum';
};

export const isTWidgetTemplateOrTEmbedTemplate = (value: any): value is TWidgetTemplate | TEmbedTemplate => {
  return value === 'side' || value === 'center' || value === 'top' || value === 'template1' || value === 'template2';
};

export type TPageType<T extends TFormType> = T extends 'widget' ? TWidget : TEmbed;
