import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from 'styles/common';
import { TitleBase } from 'styles/WidgetStyled';

const Top = ({ title, type, data }: { title: string; type: string; data: any }) => {
  const [current, setCurrent] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data[0].list.length - 1 <= current) setCurrent(0);
      else setCurrent((prev) => prev + 1);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Wrapper>
      <WidgetTitle style={{ marginTop: 3 }}>{title}</WidgetTitle>
      <Ul current={current}>
        <div>
          {data.map((pages) => {
            return pages.list.map((item, index) => {
              const key = `${type}-${index}`;
              return (
                <li key={key}>
                  {type === 'news' && (
                    <Link to={item.url} target='_blank'>
                      <Title>{item.news_title}</Title>
                    </Link>
                  )}

                  {type === 'business' && (
                    <Link to={item.gov_b_url} target='_blank'>
                      <Title>
                        <BlueB>{item.gov_b_category1 && item.gov_b_category1.split('@|#|%|/|||')[0]}</BlueB>
                        {item.gov_b_title}
                        <RedB>{item.gov_b_end_date}</RedB>
                      </Title>
                    </Link>
                  )}
                  {type === 'service' && (
                    <Link to={`https://www.gov.kr/portal/service/serviceInfo/${item.svc_id}`} target='_blank'>
                      <Title>
                        <BlueB>{item.svc_category && item.svc_category.split('@|#|%|/|')[0]}</BlueB>
                        {item.svc_name}
                        {item.svc_org && <GRAY7B>{`-${item.svc_org}`}</GRAY7B>}
                        <GRAYAB>{item.svc_date}</GRAYAB>
                      </Title>
                    </Link>
                  )}
                  {type === 'bill' && (
                    <Link to={`https://catbell.org/bill/detail/${item.lawnum}`} target='_blank'>
                      <Title>
                        <BlueB>{item.lawnum}</BlueB>
                        {item.billType && `[${item.billType}]`}
                        {item.billTitle && `${item.billTitle}-`}
                        {item.billOffer}
                        <GRAY7B>{item.org}</GRAY7B>
                        <GRAYAB>{item.offerdate}</GRAYAB>
                      </Title>
                    </Link>
                  )}
                </li>
              );
            });
          })}
        </div>
      </Ul>
    </Wrapper>
  );
};

export const WidgetTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${colors.PRIMARY};
  white-space: nowrap;
  margin-right: 3px;
`;

const Ul = styled.ul<{ current: number }>`
  width: 100%;
  position: relative;

  > div {
    position: absolute;
    right: 0;
    width: 100%;
    top: ${({ current }) => {
      return `calc(-20px * ${current})`;
    }};
    transition: top 0.3s ease 0.3s;
  }

  li {
    font-size: 12px;
    width: 100%;
    height: 20px;
  }
`;

const Title = styled(TitleBase)`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.GRAY2};
  font-size: 14px;
  vertical-align: center;
`;

const BlueB = styled.b`
  font-weight: 700;
  color: ${colors.BLUE};
  margin-right: 4px;
`;

const RedB = styled.b`
  font-weight: 700;
  color: ${colors.RED};
  margin-left: 4px;
`;

const GRAY7B = styled.b`
  color: ${colors.GRAY7};
  margin-left: 4px;
`;

const GRAYAB = styled.b`
  color: ${colors.GRAYA};
  margin-left: 4px;
`;

const Wrapper = styled.div`
  position: relative;
  height: 20px;
  overflow: hidden;
  display: flex;
`;

export default Top;
