import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-use';

import { RootState } from 'store';

const Home = () => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.user);

  const { embedPermission } = user || {};

  if (embedPermission) return <Navigate to='/embed/config' state={{ from: location }} replace />;
  return <Navigate to='/widget/config' state={{ from: location }} replace />;
};

export default Home;
