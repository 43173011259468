import { useParams } from 'react-router-dom';

import { useEmbedGet } from 'hooks/useEmbed';
import CommonForm from 'components/CommonForm';

const EmbedModify = () => {
  const { uuid } = useParams();
  const {
    data: { config },
  } = useEmbedGet({ uuid });

  return <CommonForm pageType='embed' pageSubmit='수정' data={config} uuid={uuid} />;
};

export default EmbedModify;
