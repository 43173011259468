import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  WidgetTitleBase,
  ImgVerticalWrapper,
  TitleBase,
  SummaryBase,
  ButtonBase,
  GRAYAPBase,
  GRAY7PBase,
  BluePBase,
  RedPBase,
} from 'styles/WidgetStyled';
import Loading2 from 'components/Loading2';
import { colors, flexbox } from 'styles/common';
import { AngleLeftIcon, AngleRightIcon } from 'assets/svgs';
import errorImage from 'assets/images/error-image.webp';

const Center = ({
  title,
  type,
  data,
  handlePrev,
  hasPreviousPage,
  hasNextPage,
  handleNext,
  isFetching,
  currentPage,
  view,
}: {
  title: string;
  type: string;
  data: any;
  handlePrev: MouseEventHandler;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  handleNext: MouseEventHandler;
  isFetching: boolean;
  currentPage: number;
  view: number;
}) => {
  return (
    <Wrapper $type={type}>
      {isFetching && <Loading2 />}
      <TitleWrapper>
        <WidgetTitleBase>{title}</WidgetTitleBase>

        <div>
          <StyledButton type='button' onClick={handlePrev} disabled={!hasPreviousPage}>
            <AngleLeftIcon />
          </StyledButton>
          <StyledButton type='button' onClick={handleNext} disabled={!hasNextPage}>
            <AngleRightIcon />
          </StyledButton>
        </div>
      </TitleWrapper>
      <Ul $currentPage={(currentPage - 1) / view} $isFetching={isFetching}>
        {data.map((pages, i) => {
          const pagekey = `pages-${i}`;
          return (
            <li key={pagekey}>
              <ul>
                {pages.list.map((item, index) => {
                  const key = `${item.news_title}-${index}`;
                  return (
                    <li key={key}>
                      {type === 'news' && (
                        <Link to={item.url} target='_blank'>
                          <ImgVerticalWrapper>
                            <img
                              src={item.thumbnail_url || errorImage}
                              alt={item.news_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = errorImage;
                              }}
                            />
                          </ImgVerticalWrapper>
                          <Title>{item.news_title}</Title>
                          <Summary>{item.news_text}</Summary>
                        </Link>
                      )}
                      {type === 'business' && (
                        <Link to={item.gov_b_url} target='_blank'>
                          <ColumnFlexDiv>
                            <div>
                              <BlueP>{item.gov_b_category1 && item.gov_b_category1.split('@')[0]}</BlueP>
                              <Title>{item.gov_b_title}</Title>
                            </div>
                            <RedP>{item.gov_b_end_date}</RedP>
                          </ColumnFlexDiv>
                        </Link>
                      )}
                      {type === 'service' && (
                        <Link to={`https://www.gov.kr/portal/service/serviceInfo/${item.svc_id}`} target='_blank'>
                          <ColumnFlexDiv>
                            <div>
                              <BlueP>{item.svc_category && item.svc_category.split('||')[0]}</BlueP>
                              <Title>{item.svc_name}</Title>
                            </div>
                            <RedP>{item.svc_date}</RedP>
                          </ColumnFlexDiv>
                        </Link>
                      )}
                      {type === 'bill' && (
                        <Link to={`https://catbell.org/bill/detail/${item.lawnum}`} target='_blank'>
                          <ColumnFlexDiv>
                            <RowFlexDiv>
                              <BlueP>{item.lawnum}</BlueP>
                              <BillRedp>{item.billStage}</BillRedp>
                            </RowFlexDiv>
                            <Title>
                              {item.billType && `[${item.billType}]`}
                              {item.billTitle && `${item.billTitle}-`}
                              {item.billOffer}
                            </Title>
                            <div>
                              <GRAY7P style={{ marginTop: '12px' }}>{item.org}</GRAY7P>
                              <GRAYAP>{item.offerdate}</GRAYAP>
                            </div>
                          </ColumnFlexDiv>
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </Ul>
    </Wrapper>
  );
};

export const Wrapper = styled.div<{ $type: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: ${({ $type }) => {
    if ($type === 'news') return '220px';
    if ($type === 'business') return '130px';
    if ($type === 'service') return '120px';
    if ($type === 'bill') return '150px';
    return '120px';
  }};

  a {
    display: block;
    height: 100%;
  }
`;

const RowFlexDiv = styled.div`
  ${flexbox({ ai: 'flex-start', jc: 'space-between', gap: 3 })}
`;

const ColumnFlexDiv = styled.div`
  ${flexbox({ fd: 'column', ai: 'flex-start', jc: 'space-between' })}
  height: 100%;
`;

const Ul = styled.ul<{ $currentPage: number; $isFetching: boolean }>`
  ${flexbox({ ai: 'flex-start', jc: 'space-between' })}
  width: 100%;
  position: absolute;
  left: ${({ $currentPage }) => {
    return `calc(${$currentPage} * -100%)`;
  }};
  transition: left 0.3s ease-in-out;
  transition-delay: ${({ $isFetching }) => {
    if (!$isFetching) return `0s`;
    return `86400s`;
  }};

  & > li {
    flex: 0 0 100%;
  }

  & > li > ul {
    ${flexbox({ ai: 'stretch', jc: 'space-between', gap: 30 })}

    li {
      width: 100%;
      max-width: 250px;
      min-height: 85px;
      height: 100%;
    }
  }
`;

const TitleWrapper = styled.div`
  ${flexbox({ fd: 'row', jc: 'space-between' })};
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.GRAYD};
  margin-bottom: 16px;

  button + button {
    margin-left: 8px;
  }
`;

const StyledButton = styled(ButtonBase)`
  width: 20px;

  background: ${colors.GRAYE};
  padding: 0 3px;

  svg {
    height: 8px;
    fill: ${colors.BLACK};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Title = styled(TitleBase)`
  margin-top: 8px;
  line-height: 1.3;
  height: 35px;
  //min-height: 33px;
`;

const Summary = styled(SummaryBase)`
  margin-top: 10px;
  line-height: 1.2;
`;

const GRAYAP = styled(GRAYAPBase)`
  margin-top: 8px;
`;

const GRAY7P = styled(GRAY7PBase)``;

const BlueP = styled(BluePBase)`
  font-weight: 700;
`;

const RedP = styled(RedPBase)`
  margin-top: 12px;
  //position: absolute;
  //bottom: 0;
  //width: 100%;
`;

const BillRedp = styled(RedP)`
  font-size: 12px;
  margin-top: 0;
`;

export default Center;
