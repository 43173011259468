import { useParams } from 'react-router-dom';

import { useEmbedGet } from 'hooks/useEmbed';
import { IEmbedList } from 'types/embed';
import CommonForm from 'components/CommonForm';

const EmbedRegister = () => {
  const { uuid } = useParams() || {};
  const { data } = useEmbedGet({ uuid });
  const dt: IEmbedList = data ? uuid && { ...data.config, title: `${data.config.title}-copy` } : undefined;

  return <CommonForm pageType='embed' pageSubmit='등록' data={dt} uuid={uuid} />;
};
export default EmbedRegister;
