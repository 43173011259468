import styled from 'styled-components';
import { FallbackProps } from 'react-error-boundary';

import errorImage from 'assets/images/errorpage-image.webp';
import { colors, flexbox } from 'styles/common';

const ErrorPage = ({ error }: FallbackProps) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <img src={errorImage} alt='error' width={318} height={240} />
        <div>
          <p>{error.message}</p>
          <p>{error.message.includes('timeout') && 'No response from server.'}</p>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 720px;
  height: 100vh;
  z-index: 10000;
  position: absolute;
`;

const InnerWrapper = styled.div`
  ${flexbox({ ai: 'center', gap: 60 })};
  margin: 0 auto;
  min-width: 720px;
  height: 100%;
  background-color: ${colors.WHITE};

  div {
    display: inline-block;

    p {
      font-size: 24px;
      color: #111111;
      margin-bottom: 40px;
    }

    button {
      font-size: 16px;
      text-decoration: none;
      border: 1px solid #397cc7;
      border-radius: 3px;
      color: #397cc7;
      padding: 12px 20px;
      background-color: ${colors.WHITE};
      cursor: pointer;
    }
  }
`;

export default ErrorPage;
