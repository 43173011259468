import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { instance } from 'hooks/useAxios';
import { IWidget, IWidgetGet, IWidgetId, IWidgetListGet, IWidgetPostRes } from 'types/widget';
import { TWidgetSearchRes } from 'types/frameWidgetRes';

const widgetPost = ({ title, queryString, referrerDomain, template, type }: IWidget): Promise<IWidgetPostRes> => {
  return instance
    .post('/widget/config', {
      title,
      queryString,
      referrerDomain,
      template,
      type,
    })
    .then((res) => res.data);
};

export const useWidgetPost = (): UseMutationResult<IWidgetPostRes, AxiosError> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(widgetPost, {
    onSuccess: async ({ success }: IWidgetPostRes) => {
      if (success)
        await queryClient
          .refetchQueries(['widgetList'])
          .then(() => navigate(`/widget/config`, { replace: true }))
          .then(() =>
            toast.success('위젯 생성 성공', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: 'light',
            })
          );
    },
  });
};

const widgetListGet = ({ page = 1, rowsPerPage = 10 }: { page: number; rowsPerPage?: number }): Promise<IWidgetListGet> => {
  return instance.get(`/widget/list?page=${page}&rowsPerPage=${rowsPerPage}`).then((res) => res.data);
};

export const useWidgetListGet = ({ page }: { page?: number }) => {
  return useQuery<IWidgetListGet, AxiosError>(['widgetList', page], () => widgetListGet({ page }), {
    onSuccess: async () => {
      // do something
    },
    refetchOnMount: true,
    suspense: true,
    useErrorBoundary: true,
  });
};

export const widgetGet = ({ uuid }: { uuid: string }): Promise<IWidgetGet> => {
  return instance.get(`/widget/config/${uuid}`).then((res) => {
    if (res.data.config) return res.data;
    throw new Error('Not found');
  });
};

export const useWidgetGet = ({ uuid }: { uuid: string }) => {
  return useQuery<IWidgetGet, AxiosError>(['widgetGet', uuid], () => widgetGet({ uuid }), {
    onSuccess: async () => {
      // do something
    },
    refetchOnMount: true,
    suspense: true,
    useErrorBoundary: true,
    enabled: !!uuid,
  });
};

const widgetPreviewGet = ({ qryString, type }: { qryString: string; type: string }): Promise<TWidgetSearchRes> => {
  return instance.get(`/widget/config/preview`, { params: { qryString, type } }).then((res) => res.data);
};

export const useWidgetPreviewGet = ({ qryString, type }: { qryString: string; type: string }) => {
  return useQuery<TWidgetSearchRes, AxiosError>(['widgetPreviewGet'], () => widgetPreviewGet({ qryString, type }), {
    onSuccess: async () => {
      // do something
    },
    refetchOnMount: true,
    suspense: true,
    useErrorBoundary: true,
    enabled: !!qryString && !!type,
  });
};

const widgetPut = ({ uuid, title, queryString, referrerDomain, template, type }: IWidgetId): Promise<IWidgetPostRes> => {
  return instance
    .put(`/widget/config/${uuid}`, {
      title,
      queryString,
      referrerDomain,
      template,
      type,
    })
    .then((res) => res.data);
};

export const useWidgetPut = (): UseMutationResult<IWidgetPostRes, AxiosError> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(widgetPut, {
    onSuccess: async ({ success }: IWidgetPostRes) => {
      if (success)
        await queryClient
          .refetchQueries(['widgetList'])
          .then(() => navigate(`/widget/config`, { replace: true }))
          .then(() =>
            toast.success('위젯 수정 성공', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: 'light',
            })
          );
      else
        toast.error('위젯 수정 실패', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
    },
  });
};

const widgetDelete = ({ uuid }: { uuid: string }): Promise<any> => {
  return instance.delete(`/widget/config/${uuid}`).then((res) => res.data);
};

export const useWidgetDelete = (): UseMutationResult<any, AxiosError> => {
  const queryClient = useQueryClient();
  return useMutation(widgetDelete, {
    onSuccess: async ({ success }: any) => {
      if (success) {
        await queryClient.refetchQueries(['widgetList']).then(() =>
          toast.success('위젯 삭제 성공', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
          })
        );
      } else
        toast.error('위젯 삭제 실패', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
    },
  });
};
