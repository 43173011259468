import styled from 'styled-components';
import { useEffect, useState, MouseEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AngleLeftIcon, AngleRightIcon } from 'assets/svgs';
import { colors } from 'styles/common';

interface IProps {
  totalPages: number;
}
const sliceArrayByLimit = (totalPage, limit) => {
  const t = Array.from({ length: totalPage }, (v, i) => i);
  return Array.from({ length: Math.ceil(totalPage / limit) }, () => t.splice(0, limit));
};

export const StyledPageNav = ({ totalPages }: IProps) => {
  const itemsPerPage = 5;
  const [searchParams, setSearchParams] = useSearchParams();
  const cp = searchParams.get('p') || '1';
  const cpp = searchParams.get('cpp') || '5';

  const [currentPage, setCurrentPage] = useState<number>();
  const [currentPageArray, setCurrentPageArray] = useState<number[]>();
  const [totalPageArray, setTotalPageArray] = useState<Array<number>[]>();

  useEffect(() => {
    setCurrentPage(Number(cp));
  }, [cp]);

  useEffect(() => {
    if (currentPage % itemsPerPage === 1) {
      setCurrentPageArray(totalPageArray[Math.floor(currentPage / itemsPerPage)]);
    } else if (currentPage % itemsPerPage === 0) {
      setCurrentPageArray(totalPageArray[Math.floor(currentPage / itemsPerPage) - 1]);
    }
  }, [currentPage, totalPageArray]);

  useEffect(() => {
    const slicedPageArray = sliceArrayByLimit(totalPages, itemsPerPage);
    setTotalPageArray(slicedPageArray);
    setCurrentPageArray(slicedPageArray[0]);
  }, [totalPages]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    searchParams.set('cpp', cpp);
    searchParams.set('p', e.currentTarget.value);
    setSearchParams(searchParams);
  };

  const handlePrev = () => {
    searchParams.set('cpp', cpp);
    searchParams.set('p', String(currentPage - 5));
    setSearchParams(searchParams);
    // setSearchParams({ ...searchParams.get('cpp'), p: String(currentPage - 5) });
  };

  const handleNext = () => {
    searchParams.set('cpp', cpp);
    searchParams.set('p', String(currentPage + 5));
    setSearchParams(searchParams);
    // setSearchParams({ ...searchParams.get('cpp'), p: String(currentPage + 5) });
  };

  return (
    <Page>
      {totalPageArray && totalPageArray.length > 1 && (
        <li>
          <button type='button' onClick={handlePrev} disabled={currentPage - 5 < 1}>
            <AngleLeftIcon />
          </button>
        </li>
      )}
      {currentPageArray &&
        currentPageArray.map((item) => {
          const key = `page-${item}`;
          return (
            <li key={key} className={currentPage === item + 1 ? 'on' : ''}>
              <button type='button' value={item + 1} onClick={handleClick}>
                {item + 1}
              </button>
            </li>
          );
        })}
      {totalPageArray && totalPageArray.length > 1 && (
        <li>
          <button type='button' onClick={handleNext} disabled={currentPage + 9 > totalPages}>
            <AngleRightIcon />
          </button>
        </li>
      )}
    </Page>
  );
};

export const Page = styled.ol`
  text-align: center;
  margin-top: 30px;

  li {
    display: inline-block;
    border: 1px solid ${colors.PRIMARY};
    cursor: pointer;
  }

  button {
    font-size: 15px;
    color: #1b3a6a;
    min-width: 36px;
    line-height: 36px;

    svg {
      position: relative;
      top: 1px;
      height: 14px;
      fill: ${colors.PRIMARY};
    }

    &:disabled {
      cursor: not-allowed;

      svg {
        fill: ${colors.GRAYA};
      }
    }
  }

  li:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  li:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  li + li {
    border-left: none;
  }

  li.on {
    background: #1b3a6a;

    button {
      color: #ffffff;
    }
  }
`;
