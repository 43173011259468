import Modal from 'react-modal';
import { MouseEventHandler, Suspense } from 'react';
import styled from 'styled-components';

import { IQuery } from 'types/form';
import { FormName, TFormType, TPageType } from 'types/enum';
import Loading from 'components/Loading';

import { modalStyle } from 'styles/common';
import { CloseIcon } from 'assets/svgs';
import { ModalDiv } from 'styles/StyledComponent';
import WidgetDataFetch from './WidgetDataFetch';
import EmbedDataFetch from './EmbedDataFetch';

interface IProps {
  pageType: TFormType;
  isOpen: boolean;
  handleModalClose: MouseEventHandler;
  formData: IQuery;
}

const CommonPreview = ({ pageType, isOpen, handleModalClose, formData }: IProps) => {
  if (!formData) return undefined;

  const { title, type, template, queryString } = formData;

  return (
    <Modal isOpen={isOpen} ariaHideApp={false} shouldCloseOnOverlayClick={false} style={modalStyle}>
      <ModalWrapper>
        <div className='title'>
          <Title>{FormName[pageType]} 미리보기</Title>
          <button type='button' onClick={handleModalClose}>
            <CloseIcon />
          </button>
        </div>
        <div>
          <Suspense fallback={<Loading />}>
            {pageType === 'widget' && (
              <WidgetDataFetch title={title} type={type as TPageType<'widget'>} template={template} queryString={queryString} view={5} />
            )}
            {pageType === 'embed' && (
              <EmbedDataFetch title={title} type={type as TPageType<'embed'>} template={template} queryString={queryString} view={5} />
            )}
          </Suspense>
        </div>
      </ModalWrapper>
    </Modal>
  );
};

const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 25px;
`;

const ModalWrapper = styled(ModalDiv)`
  min-width: 400px;
  max-height: 520px;
  overflow: hidden;

  > div:last-of-type {
    max-height: 400px;
    overflow: scroll;
  }
`;

export default CommonPreview;
