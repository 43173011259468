import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import { widgetGet } from 'hooks/useWidget';
import BlockPage from 'components/BlockPage';
import { IWidgetGet } from 'types/widget';

import DataFetch from './DataFetch';

const IFrameWidget = () => {
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();
  const cpp = searchParams.get('cpp') || '5';

  const { data, isError } = useQuery<IWidgetGet, AxiosError>(['widgetGet', uuid], () => widgetGet({ uuid }), {
    enabled: !!uuid,
  });

  if (!data) return undefined;
  if (isError) return <BlockPage />;

  const {
    config: { referrerDomain },
  } = data;

  if (document.referrer.indexOf(referrerDomain) < 0) return <BlockPage />;

  return <DataFetch config={data.config} uuid={uuid} view={Number(cpp)} />;
};
export default IFrameWidget;
