import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useUserLoginPost } from 'hooks/useUser';
import StyledInput from 'components/StyledInput';
import { Radio, StyledRadio } from 'components/StyledRadio';
import StyledUploadfile from 'components/StyledUploadfile';

import { LogoIcon } from 'assets/svgs';
import { flexbox } from 'styles/common';
import { BigButton } from 'styles/StyledComponent';

const Register = () => {
  const navigate = useNavigate();
  const { mutate } = useUserLoginPost();

  const [pwd, setPwd] = useState<string>();
  const [checkPwd, setCheckPwd] = useState<string>();
  const [isSame, setSame] = useState<boolean>(false);
  // useUserGet();

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    // console.log(formJson);

    mutate(formJson, {
      onSuccess: () => {
        navigate(`/`, { replace: true });
      },
    });
  };

  useEffect(() => {
    if (pwd === checkPwd) setSame(true);
    else setSame(false);
  }, [pwd, checkPwd]);

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <NavLink to='/'>
          <LogoIcon />
        </NavLink>
        <StyledInput name='id' label='아이디' placeholder='아이디를 입력하세요.' required />
        <StyledInput
          name='password'
          type='password'
          label='비밀번호'
          placeholder='비밀번호를 입력하세요.'
          value={pwd || ''}
          onChange={(e) => setPwd(e.currentTarget.value)}
          autoComplete='current-password"'
          required
        />
        <StyledInput
          label={isSame ? '비밀번호 확인' : '비밀번호 확인 (비밀번호가 일치하지 않습니다)'}
          placeholder='비밀번호를 다시 입력하세요.'
          value={checkPwd || ''}
          onChange={(e) => setCheckPwd(e.currentTarget.value)}
          required
        />
        <StyledInput name='name' label='파트너 이름' placeholder='설립 명칭을 입력하세요.' required />

        <StyledRadio name='receipt' label='기부금 영수증 발급 가능 여부'>
          <Radio value='가능'>가능</Radio>
          <Radio value='불가능'>불가능</Radio>
        </StyledRadio>

        <StyledUploadfile label='설립 등록증 (Jpeg, Png, Pdf 파일만 업로드 가능합니다.)' />

        <StyledInput name='managerName' label='담당자 이름' placeholder='담당자 이름을 입력하세요.' required />
        <StyledInput name='managerContact' label='담당자 연락처' placeholder='담당자 연락처를 입력하세요.' required />
        <StyledInput name='managerEmail' label='담당자 이메일' placeholder='담당자 이메일을 입력하세요.' required />
        <BigButton type='submit'>파트너 가입 요청</BigButton>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${flexbox({ jc: 'center', ai: 'center' })};
`;

export const Form = styled.form`
  width: 400px;
  padding: 50px 0;

  > a {
    svg {
      height: 200px;
    }
  }

  > div {
    margin-bottom: 20px;
  }
`;

export default Register;
