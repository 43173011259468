import { Dispatch, SetStateAction } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { IBillRes } from 'types/frameWidgetRes';
import { StyledPageNav } from 'components/StyledPageNav';

import { Container } from 'styles/StyledComponent';
import { colors, textEllipsis, flexbox } from 'styles/common';
import { MagnifyingGlassIcon } from 'assets/svgs';
import state0 from 'assets/images/state-접수.webp';
import state1 from 'assets/images/state-위원회심사.webp';
import state2 from 'assets/images/state-체계자구심사.webp';
import state3 from 'assets/images/state-본회의심의.webp';
import state4 from 'assets/images/state-정부이송.webp';
import state5 from 'assets/images/state-공포.webp';

const billStage: { [key: number]: JSX.Element } = {
  0: <img src={state0} alt='접수' />,
  1: <img src={state1} alt='위원회심사' />,
  2: <img src={state2} alt='체계자구심사' />,
  3: <img src={state3} alt='본회의심사' />,
  4: <img src={state4} alt='정부이송' />,
  5: <img src={state5} alt='공포' />,
};

const BillTable = ({
  title,
  type,
  list,
  view,
  template,
  totcnt,
  srchText,
  setSrchText,
}: {
  title: string;
  type: string;
  list: IBillRes[];
  view: number;
  template: string;
  totcnt: number;
  srchText: string;
  setSrchText: Dispatch<SetStateAction<string>>;
}) => {
  // eslint-disable-next-line no-nested-ternary
  const totalPage = totcnt ? (Math.ceil(totcnt / view) === 0 ? 1 : Math.ceil(totcnt / view)) : 1;
  const [searchParams, setSearchParams] = useSearchParams();
  interface IForm {
    srchText: string;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form) as unknown as Iterable<[IForm, FormDataEntryValue]>;
    const formJson: IForm = Object.fromEntries(formData);
    searchParams.set('cpp', searchParams.get('cpp'));
    searchParams.set('p', '1');
    setSearchParams(searchParams);
    setSrchText(formJson.srchText);
  };

  const findBillStage = (st: string) => {
    if (st === '위원회심사') return billStage[1];
    if (st === '체계자구심사') return billStage[2];
    if (st === '본회의심사') return billStage[3];
    if (st === '정부이송') return billStage[4];
    if (st === '공포') return billStage[5];
    return billStage[0];
  };

  // console.log(list);

  return (
    <Wrapper>
      <TitleWrapper>
        <div>
          <h1>{title}</h1>
          <p>
            검색결과 <span>{totcnt}</span>건, <span>{totalPage}</span>페이지
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <button type='submit'>
            <MagnifyingGlassIcon />
          </button>
          <input type='text' name='srchText' placeholder='검색할 단어를 적어주세요.' defaultValue={srchText} />
        </form>
      </TitleWrapper>
      <Container>
        <div>
          {template === 'template1' && (
            <StyledTable className='result-table board'>
              {type === 'bill' && (
                <colgroup>
                  <col width='40%' />
                  <col width='20%' />
                  <col width='20%' />
                  <col width='20%' />
                </colgroup>
              )}
              {type === 'lawnum' && (
                <colgroup>
                  <col width='60%' />
                  <col width='20%' />
                  <col width='20%' />
                </colgroup>
              )}
              <thead>
                {type === 'bill' && (
                  <tr>
                    <th>법안정보</th>
                    <th>기관</th>
                    <th>상태</th>
                    <th>키워드</th>
                  </tr>
                )}
                {type === 'lawnum' && (
                  <tr>
                    <th>법안정보</th>
                    <th>기관</th>
                    <th>상태</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {list.map((item, index) => {
                  const key = `widget-${index}`;
                  return (
                    <tr key={key}>
                      <td>
                        <p>
                          <StyledSpanLawnum0 data-before={item.lawnum.substring(0, 2)}>{item.lawnum}</StyledSpanLawnum0>
                          <StyledSpan className='normal'>{item.billType}</StyledSpan>
                          {item.lawSpecial > 0 && <StyledSpanRed>특별</StyledSpanRed>}
                        </p>
                        <Link to={`https://catbell.org/bill/detail/${item.lawnum}`}>{item.billTitle}</Link>
                        <p>{item.billOffer}</p>
                        <p className='gray78'>{item.offerdate}</p>
                      </td>
                      <td>{item.org}</td>
                      <td>{item.billStage}</td>
                      {type === 'bill' && <td className='gray78'>{item.hashtags}</td>}
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          )}
          {template === 'template2' && (
            <ul>
              {list.map((item, index) => {
                const key = `widget-${index}`;
                return (
                  <StyledLi key={key}>
                    <div>
                      <div>
                        <StyledSpanLawnum1 data-before='법안번호'>{item.lawnum}</StyledSpanLawnum1>
                        <StyledSpanFill>{item.billType}</StyledSpanFill>
                        {item.lawSpecial > 0 && <StyledSpanRed>특별</StyledSpanRed>}
                      </div>
                      {type === 'bill' && <p className='sky'>{item.hashtags}</p>}
                    </div>
                    <div>
                      <div>
                        <p className='gray7'>{item.org}</p>
                        <Link className='title' to={`https://catbell.org/bill/detail/${item.lawnum}`}>
                          {item.billTitle}
                        </Link>

                        <p className='offer'>{item.billOffer}</p>
                        <p className='gray7'>{item.offerdate}</p>
                      </div>
                      {findBillStage(item.billStage)}
                    </div>
                  </StyledLi>
                );
              })}
            </ul>
          )}
        </div>
      </Container>
      <StyledPageNav totalPages={totalPage} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  margin: 20px auto;
  padding-bottom: 20px;
  border-bottom: 2px solid ${colors.GRAY2};
  ${flexbox({ fd: 'row', jc: 'space-between' })};

  h1 {
    font-size: 36px;
    font-weight: 700;
    color: ${colors.PRIMARY};
    display: inline-block;
    margin-right: 16px;
  }

  p {
    display: inline-block;
    font-size: 13px;
    span {
      color: ${colors.SECONDARY};
    }
  }

  form {
    border-bottom: 1px solid ${colors.GRAYD};
    position: relative;

    input {
      border: transparent;
      line-height: 40px;
      width: 180px;
      background: transparent;
      margin-left: 25px;
    }

    button {
      position: absolute;
      left: 0;
      bottom: 0;
      line-height: 40px;
      padding-right: 8px;

      svg {
        position: relative;
        top: 4px;
      }
    }
  }
`;

const StyledLi = styled.li`
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 20px 0;

  & > div {
    ${flexbox({ fd: 'row', jc: 'space-between' })};
  }

  .sky {
    color: ${colors.SECONDARY};
    font-size: 13px;
  }

  .gray7 {
    color: ${colors.GRAY7};
    font-size: 14px;
    padding-top: 16px;
  }

  .title {
    color: ${colors.BLACK};
    font-size: 16px;
    padding: 8px 0 20px;
    display: inline-block;
  }

  .offer {
    color: ${colors.GRAY4};
    font-size: 14px;
  }

  & + li {
    border-top: 1px solid ${colors.GRAY9};
  }
`;

const StyledTable = styled.table`
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  border-collapse: collapse;
  text-align: center;

  thead {
    border-bottom: 1px solid ${colors.BLACK};

    th {
      padding: 10px;
      line-height: 1.5;
      color: #787878;
    }
  }

  tr {
    border-bottom: 1px solid ${colors.GRAY78};
  }
  td {
    padding: 8px;
    line-height: 1.5;
    color: ${colors.GRAY2};
    vertical-align: middle;
  }

  .gray78 {
    color: ${colors.GRAY78};
    text-align: left;
  }

  td:first-of-type {
    text-align: left;

    span {
      border-radius: 10px;
    }

    p:first-of-type {
      ${textEllipsis({ n: 1 })};
    }

    a,
    a:visited {
      ${textEllipsis({ n: 2 })};
      color: ${colors.BLACK};
      font-weight: 700;
      line-height: 1.2;
      margin-top: 8px;
    }
  }
`;

const StyledSpan = styled.span`
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 0 3px 0 7px;
  height: 20px;
  line-height: 20px;
  letter-spacing: 4px;
  font-size: 12px;
  color: ${colors.PRIMARY};
  border-radius: 4px;
  border: solid 1px ${colors.PRIMARY};
  background-color: ${colors.WHITE};
  font-weight: 700;

  & + span {
    margin-left: 4px;
  }
`;

const StyledSpanLawnum0 = styled(StyledSpan)`
  padding-left: 31px;

  &::before {
    content: attr(data-before);
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 20px;
    color: ${colors.WHITE};
    padding-left: 8px;
    background-color: ${colors.PRIMARY};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 10px;
  }
`;

const StyledSpanLawnum1 = styled(StyledSpan)`
  padding-left: 90px;

  &::before {
    content: attr(data-before);
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 75px;
    color: ${colors.WHITE};
    padding-left: 8px;
    background-color: ${colors.PRIMARY};
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
`;

const StyledSpanFill = styled(StyledSpan)`
  color: ${colors.WHITE};
  border-color: ${colors.PRIMARY};
  background-color: ${colors.PRIMARY};
`;

const StyledSpanRed = styled(StyledSpan)`
  color: ${colors.WHITE};
  border: solid 1px ${colors.RED};
  background-color: ${colors.RED};
`;

export default BillTable;
