import styled from 'styled-components';

import { IAsmRes } from 'types/frameWidgetRes';

import { colors } from 'styles/common';
import { H1 } from 'styles/StyledComponent';
import congresslogoFalse from 'assets/images/congress-logo-f.webp';
import congresslogoTrue from 'assets/images/congress-logo.webp';
import theMost from 'assets/images/themost.webp';
import errorImage from 'assets/images/error-person.png';

const AssmanTable = ({ title, template, list }: { title: string; template: string; list: IAsmRes[] }) => {
  return (
    <>
      <H1>{title}</H1>
      <Ul>
        {list.map((item, index) => {
          const key = `widget-${index}`;
          return (
            <Li key={key} template={template}>
              {template === 'template2' && index === 0 && <MostImg src={theMost} alt='most' />}
              <ImageWrapper>
                <div>
                  <img
                    src={item.photo || errorImage}
                    alt={item.name_kr}
                    width={80}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = errorImage;
                    }}
                  />
                </div>
                <img className='congress' src={item.active_duty ? congresslogoTrue : congresslogoFalse} alt='congress' />
              </ImageWrapper>

              <div>
                <PartyP>
                  {item.party}
                  {template === 'template1' && index === 0 && <img src={theMost} alt='most' />}
                </PartyP>
                <p>
                  <NameSpan>{item.name_kr}</NameSpan>
                  <DistrictSpan>{item.district}</DistrictSpan>
                </p>
              </div>

              <div>
                <TitleP>대표발의 </TitleP>
                <CountP>
                  {item.pri_cnt}
                  <span>건</span>
                </CountP>
              </div>
              <div>
                <TitleP>공동발의</TitleP>
                <CountP>
                  {item.co_cnt}
                  <span>건</span>
                </CountP>
              </div>
            </Li>
          );
        })}
      </Ul>
    </>
  );
};

const Ul = styled.ul`
  width: 100%;
  max-width: 720px;
  margin: 10px auto;

  .gray78 {
    color: ${colors.GRAY78};
    text-align: left;
  }
`;

const MostImg = styled.img`
  position: absolute;
  right: 12px;
  top: 3px;
  width: 20px;
`;

const PartyP = styled.p`
  color: ${colors.PRIMARY};
  font-size: 12px;
  margin-bottom: 4px;
  position: relative;

  img {
    position: absolute;
    top: -10px;
    width: 15px;
    margin-left: 5px;
  }
`;

const TitleP = styled.p`
  color: ${colors.GRAY7};
  text-align: right;
  font-size: 14px;
`;

const CountP = styled.p`
  color: ${colors.GRAY2};
  font-weight: 600;
  text-align: right;
  font-size: 24px;
  height: 30px;

  span {
    font-weight: normal;
    font-size: 16px;
    margin-left: 3px;
  }
`;

const NameSpan = styled.span`
  color: ${colors.BLACK};
  font-size: 20px;
  font-weight: 500;
`;

const DistrictSpan = styled.span`
  color: ${colors.GRAY5};
  font-size: 16px;
  margin-left: 8px;
`;

const Li = styled.li<{ template: string }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  align-items: center;

  text-align: left;
  height: 120px;

  padding: ${({ template }) => {
    if (template === 'template1') return '20px 0';
    return '20px';
  }};

  & + li {
    margin-top: 8px;
  }

  ${({ template }) => {
    if (template === 'template1') return `& + li { border-top: 1px solid ${colors.GRAYE}; }`;
    if (template === 'template2') return `border: 1px solid ${colors.GRAYE}; border-radius: 12px;box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04)`;
    return `undefined`;
  }};
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 85px;
  height: 85px;

  > img {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  > div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid ${colors.GRAYD};
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

export default AssmanTable;
