import { compareArray } from './compareArray';

export const structQryText = ({
  elecnum,
  includeKeywords,
  excludeKeywords,
  searchDateFrom,
  searchDateTo,
  opt0,
  opt1,
  opt2,
  opt3,
}: {
  elecnum?: string;
  includeKeywords?: string[];
  excludeKeywords?: string[];
  searchDateFrom?: string;
  searchDateTo?: string;
  opt0?: string;
  opt1?: string;
  opt2?: string;
  opt3?: string;
}) => {
  const includeQuery = includeKeywords && includeKeywords.length > 0 ? structAndOrQuery(includeKeywords) : '';
  const excludeQuery = excludeKeywords && excludeKeywords.length > 0 ? ` not ${structAndOrQuery(excludeKeywords)}` : '';
  const dateQuery = !!searchDateFrom && !!searchDateTo ? ` date ( ${searchDateFrom} to ${searchDateTo} )` : '';
  // opt0 검색, opt1 입법종류, opt2 계류의안, opt3 처리의안
  const optQuery =
    !!opt0 || !!opt1 || !!opt2 || !!opt3
      ? ` opts ( ${structOpt0Query(opt0)},${orOperation(checkAlltypeA(opt1))},${orOperation(checkAlltypeB(opt2))},${orOperation(
          checkAlltypeB(opt3)
        )} )`
      : '';
  const elecnumQuery = elecnum
    ? ` elecnum ( ${elecnum
        .split(',')
        .map((str) => str.trim())
        .join('')} )`
    : '';
  return [includeQuery, excludeQuery, dateQuery, optQuery, elecnumQuery].filter((i) => i !== '').join('');
};

export const parseQrystring = (qryText: string) => {
  let qryString = qryText.trim();
  const keyElecnum = 'elecnum (';
  const keyOpts = 'opts (';
  const keyDate = 'date (';
  const keyDateSplit = ' to ';
  const keyExclude = 'not (';
  const rightParenthesis = ')';

  let includeKeywords: string[] = [];
  let excludeKeywords: string[] = [];
  let searchDateFrom = '';
  let searchDateTo = '';
  const opt0: string[] = [];
  let opt1: string[] = [];
  let opt2: string[] = [];
  let opt3: string[] = [];
  const elecnum: string[] = [];

  if (qryString.indexOf(keyElecnum) !== -1) {
    const enumQry = qryString.substring(qryString.lastIndexOf(keyElecnum) + 10, qryString.lastIndexOf(rightParenthesis));
    qryString = qryString.substring(0, qryString.lastIndexOf(keyElecnum));

    if (enumQry.includes('21')) elecnum.push('21');
    if (enumQry.includes('20')) elecnum.push('20');
    if (enumQry.includes('19')) elecnum.push('19');
    if (enumQry.includes('18')) elecnum.push('18');
  }

  if (qryString.indexOf(keyOpts) !== -1) {
    const optsQry = qryString
      .substring(qryString.lastIndexOf(keyOpts) + 7, qryString.lastIndexOf(rightParenthesis))
      .replaceAll(' ', '')
      .split(',');
    qryString = qryString.substring(0, qryString.lastIndexOf(keyOpts));

    if (optsQry[0] === 'true') opt0.push('noticeOnly');
    if (optsQry[1] === 'true') opt0.push('altOnly');
    if (optsQry[2] === 'true') opt0.push('registered');

    if (optsQry[3] === '99') opt1 = ['99'];
    else opt1 = reverseOrOperation(parseInt(optsQry[3], 10));

    if (optsQry[4] === '1') opt2 = ['1'];
    else opt2 = reverseOrOperation(parseInt(optsQry[4], 10));

    if (optsQry[5] === '1') opt3 = ['1'];
    else opt3 = reverseOrOperation(parseInt(optsQry[5], 10));
  }

  if (qryString.indexOf(keyDate) !== -1) {
    const dateQry = qryString
      .substring(qryString.lastIndexOf(keyDate) + 7, qryString.lastIndexOf(rightParenthesis))
      .trim()
      .split(keyDateSplit);

    qryString = qryString.substring(0, qryString.lastIndexOf(keyDate));
    searchDateFrom = dateQry[0];
    searchDateTo = dateQry[1];
  }

  if (qryString.indexOf(keyExclude) !== -1) {
    const excludeQry = qryString.substring(qryString.lastIndexOf(keyExclude) + 5, qryString.lastIndexOf(rightParenthesis));
    qryString = qryString.substring(0, qryString.lastIndexOf(keyExclude)).trim();
    excludeKeywords = [excludeQry.replaceAll(' or ', ',')];
  }

  if (qryString.length > 0) {
    includeKeywords = qryString
      .replaceAll('(', '')
      .replaceAll(')', '')
      .split(' and ')
      .map((w) => {
        return w.replaceAll(' or ', ',');
      });
  }

  return { elecnum, opt0, opt1, opt2, opt3, searchDateFrom, searchDateTo, excludeKeywords, includeKeywords };
};

const checkAlltypeA = (opt: string): string => {
  const typeA = ['1', '2', '4', '8'];
  const word = opt.split(',');
  if (compareArray(word, typeA)) return '99';
  return opt;
};

const checkAlltypeB = (opt: string): string => {
  const typeB = ['2', '4', '8', '16', '32', '64'];
  const word = opt.split(',');
  if (compareArray(word, typeB)) return '1';
  return opt;
};

const structAndOrQuery = (query: string[]): string => {
  return query
    .map(
      (str) =>
        `(${str
          .split(',')
          .map((word) => word.trim())
          .join(' or ')})`
    )
    .join(' and ');
};

const structOpt0Query = (opt0: string) => {
  const result = [];
  result[0] = opt0.includes('noticeOnly') ? 'true' : 'false';
  result[1] = opt0.includes('altOnly') ? 'true' : 'false';
  result[2] = opt0.includes('registered') ? 'true' : 'false';
  return result.join(',');
};

const orOperation = (input: string): number => {
  const values = input.split(',');
  // eslint-disable-next-line no-bitwise
  return values.reduce((acc, value) => acc | parseInt(value, 10), 0);
};

const reverseOrOperation = (n: number): string[] => {
  const result: string[] = [];
  for (let i = 1; i <= n; i *= 2) {
    // eslint-disable-next-line no-bitwise
    if (n & i) {
      result.push(i.toString());
    }
  }
  return result;
};
