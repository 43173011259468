import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import notosansL from 'assets/fonts/NotoSansKR-Light.woff2';
import notosansR from 'assets/fonts/NotoSansKR-Regular.woff2';
import notosansM from 'assets/fonts/NotoSansKR-Medium.woff2';
import notosansB from 'assets/fonts/NotoSansKR-Bold.woff2';

const CommonStyle = createGlobalStyle`
  ${reset};
  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 300;
    src: url(${notosansL}) format('woff2');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    src: url(${notosansR}) format('woff2');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    src: url(${notosansM}) format('woff2');
  }

  @font-face {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    src: url(${notosansB}) format('woff2');
  }
  
body {
  width: 100vw;
  font-family: 'Noto Sans KR', Arial, PingFangSC-Regular, 'Microsoft YaHei', sans-serif, '나눔고딕', NanumGothic;
  font-feature-settings: 'tnum';
}


  * {
    box-sizing: border-box;
  }
  
  button {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  a {
    text-decoration: none;
  }

  svg {
    height: 18px;
  }
  
  .hidden {
    position: fixed;
    top: -9999px;
    right: -9999px;
    width: 0;
    height: 0;
    //opacity: 0;
  }

  .hide {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    z-index: -99999;
  }
`;
export default CommonStyle;
