import { useParams } from 'react-router-dom';

import { useWidgetGet } from 'hooks/useWidget';
import { IWidgetList } from 'types/widget';
import CommonForm from 'components/CommonForm';

const WidgetRegister = () => {
  const { uuid } = useParams() || {};
  const { data } = useWidgetGet({ uuid });
  const dt: IWidgetList = data ? uuid && { ...data.config, title: `${data.config.title}-copy` } : undefined;

  return <CommonForm pageType='widget' pageSubmit='등록' data={dt} uuid={uuid} />;
};
export default WidgetRegister;
