import styled from 'styled-components';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

import Loading2 from 'components/Loading2';

import {
  FlexDiv,
  BluePBase,
  RedPBase,
  GRAY7PBase,
  GRAYAPBase,
  ImgHorizontalWrapper,
  SummaryBase,
  TitleBase,
  WidgetTitleBase,
  ButtonBase,
} from 'styles/WidgetStyled';
import { colors, flexbox, textEllipsis } from 'styles/common';
import { AngleDownIcon, AngleUpIcon } from 'assets/svgs';
import errorImage from 'assets/images/error-image.webp';

const Side = ({
  title,
  type,
  data,
  handlePrev,
  hasPreviousPage,
  hasNextPage,
  handleNext,
  isFetching,
  currentPage,
  view,
}: {
  title: string;
  type: string;
  data: any;
  handlePrev: MouseEventHandler;
  hasPreviousPage: boolean;
  handleNext: MouseEventHandler;
  hasNextPage: boolean;
  isFetching: boolean;
  currentPage: number;
  view: number;
}) => {
  return (
    <Wrapper>
      {isFetching && <Loading2 />}
      <WidgetTitleBase>{title}</WidgetTitleBase>
      <PrevButton type='button' onClick={handlePrev} disabled={!hasPreviousPage}>
        <AngleUpIcon />
      </PrevButton>

      <Window $type={type} $view={view} $currentPage={currentPage} $isFetching={isFetching}>
        <ul>
          {data.map((pages, i) => {
            const pagekey = `pages-${i}`;
            return (
              <li key={pagekey}>
                {pages.list.map((item, index) => {
                  const key = `${item.news_title}-${index}`;
                  return (
                    <div className='ul' key={key}>
                      {type === 'news' && (
                        <Link to={item.url} target='_blank'>
                          <ImgHorizontalWrapper bg={item.thumbnail_url}>
                            <img
                              src={item.thumbnail_url || errorImage}
                              alt={item.news_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = errorImage;
                              }}
                            />
                          </ImgHorizontalWrapper>
                          <Title>{item.news_title}</Title>
                          <Summary>{item.news_text}</Summary>
                        </Link>
                      )}
                      {type === 'business' && (
                        <Link to={item.gov_b_url} target='_blank'>
                          <BlueP>{item.gov_b_category1 && item.gov_b_category1.split('@')[0]}</BlueP>
                          <Title>{item.gov_b_title}</Title>
                          <RedP>{item.daysLeft && item.daysLeft < 0 ? `D${item.daysLeft}` : 'End'}</RedP>
                        </Link>
                      )}
                      {type === 'service' && (
                        <Link to={`https://www.gov.kr/portal/service/serviceInfo/${item.svc_id}`} target='_blank'>
                          <BlueP>{item.svc_category && item.svc_category.split('||')[0]}</BlueP>
                          <Title>{item.svc_name}</Title>
                          <GRAY7P>{item.svc_org}</GRAY7P>
                          <GRAYAP>{item.svc_date}</GRAYAP>
                        </Link>
                      )}
                      {type === 'bill' && (
                        <Link to={`https://catbell.org/bill/detail/${item.lawnum}`} target='_blank'>
                          <FlexDiv>
                            <BlueP>{item.lawnum}</BlueP>
                            <RedP style={{ fontSize: '12px' }}>{item.billStage}</RedP>
                          </FlexDiv>
                          <Title>
                            {item.billType && `[${item.billType}]`}
                            {item.billTitle && `${item.billTitle}-`}
                            {item.billOffer}
                          </Title>
                          <GRAY7P>{item.org}</GRAY7P>
                          <GRAYAP>{item.offerdate}</GRAYAP>
                        </Link>
                      )}
                    </div>
                  );
                })}
              </li>
            );
          })}
        </ul>
      </Window>
      <NextButton type='button' onClick={handleNext} disabled={!hasNextPage}>
        <AngleDownIcon />
      </NextButton>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;
`;

const Window = styled.div<{ $type: string; $view: number; $currentPage: number; $isFetching: boolean }>`
  position: relative;
  height: ${({ $type, $view }) => {
    if ($type === 'news') return `calc(${$view} * 200px)`;
    if ($type === 'business') return `calc(${$view} * 130px)`;
    if ($type === 'service') return `calc(${$view} * 120px)`;
    if ($type === 'bill') return `calc(${$view} * 150px)`;
    return '120px';
  }};
  overflow: hidden;

  > ul {
    width: 100%;
    position: absolute;
    top: ${({ $type, $currentPage }) => {
      let calculate;
      if ($type === 'news') calculate = ($currentPage - 1) * 200;
      if ($type === 'business') calculate = ($currentPage - 1) * 130;
      if ($type === 'service') calculate = ($currentPage - 1) * 120;
      if ($type === 'bill') calculate = ($currentPage - 1) * 150;
      return `-${calculate}px`;
    }};
    transition: top 0.3s ease-in-out;
    transition-delay: ${({ $isFetching }) => {
      if (!$isFetching) return `0s`;
      return `86400s`;
    }};

    .ul {
      ${flexbox({ jc: 'flex-start', ai: 'center', gap: 10 })};

      min-height: ${({ $type }) => {
        if ($type === 'news') return '200px';
        if ($type === 'business') return '130px';
        if ($type === 'service') return '120px';
        if ($type === 'bill') return '150px';
        return '120px';
      }};

      a {
        display: block;
        width: 100%;
      }
    }
  }
`;

export const PrevButton = styled(ButtonBase)`
  width: 100%;
  margin-top: 12px;
  background: ${colors.BABY_BLUE};

  svg {
    fill: ${colors.WHITE};
    position: relative;
    top: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${colors.GRAYE};

    svg {
      fill: ${colors.GRAY5};
      position: relative;
      top: 2px;
    }
  }
`;

export const NextButton = styled(ButtonBase)`
  width: 100%;
  background: ${colors.BABY_BLUE};

  svg {
    fill: ${colors.WHITE};
    position: relative;
    top: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${colors.GRAYE};

    svg {
      fill: ${colors.GRAY5};
      position: relative;
      top: 2px;
    }
  }
`;

export const Title = styled(TitleBase)`
  margin: 8px 0;
  line-height: 1.3;
  //min-height: 33px;
`;

export const Summary = styled(SummaryBase)`
  margin-top: 10px;
  min-height: 12px;
  line-height: 1.2;
`;

export const GRAYAP = styled(GRAYAPBase)`
  margin-top: 8px;
`;

export const GRAY7P = styled(GRAY7PBase)`
  ${textEllipsis({ n: 1 })};
`;

export const BlueP = styled(BluePBase)`
  font-weight: 700;
`;

export const RedP = styled(RedPBase)`
  text-align: right;
`;

export default Side;
