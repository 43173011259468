import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { instance } from 'hooks/useAxios';
import { IEmbed, IEmbedGet, IEmbedId, IEmbedListGet, IEmbedPostRes } from 'types/embed';

const EmbedPost = ({ title, queryString, referrerDomain, template, type }: IEmbed): Promise<IEmbedPostRes> => {
  return instance
    .post('/embed/config', {
      title,
      queryString,
      referrerDomain,
      template,
      type,
    })
    .then((res) => res.data);
};

export const useEmbedPost = (): UseMutationResult<IEmbedPostRes, AxiosError> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(EmbedPost, {
    onSuccess: async ({ success }: IEmbedPostRes) => {
      if (success) {
        await navigate(`/embed/config`, { replace: true });
        await queryClient.refetchQueries(['embedList']).then(() =>
          toast.success('임베드 생성 성공', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
          })
        );
      }
    },
  });
};

const embedListGet = ({ page = 1, rowsPerPage = 10 }: { page: number; rowsPerPage?: number }): Promise<IEmbedListGet> => {
  return instance.get(`/embed/list?page=${page}&rowsPerPage=${rowsPerPage}`).then((res) => res.data);
};

export const useEmbedListGet = ({ page }: { page?: number }) => {
  return useQuery<IEmbedListGet, AxiosError>(['embedList', page], () => embedListGet({ page }), {
    onSuccess: async () => {
      // do something
    },
    refetchOnMount: true,
    suspense: true,
    useErrorBoundary: true,
  });
};

const embedGet = ({ uuid }: { uuid: string }): Promise<IEmbedGet> => {
  return instance.get(`/embed/config/${uuid}`).then((res) => {
    if (res.data.config) return res.data;
    throw new Error('Not found');
  });
};

export const useEmbedGet = ({ uuid }: { uuid: string }) => {
  return useQuery<IEmbedGet, AxiosError>(['embedGet', uuid], () => embedGet({ uuid }), {
    onSuccess: async () => {
      // do something
    },
    refetchOnMount: true,
    suspense: true,
    useErrorBoundary: true,
    enabled: !!uuid,
  });
};

const embedSearchGet = ({ uuid, page = 1, rowsPerPage = 3, srchText }: { uuid: string; page: number; rowsPerPage: number; srchText?: string }) => {
  return instance
    .get(`/embed/search/${uuid}`, {
      params: { page, rowsPerPage, srchText },
    })
    .then((res) => res.data);
};

export const useEmbedSearchGet = ({
  uuid,
  page = 1,
  rowsPerPage = 5,
  srchText,
}: {
  uuid: string;
  page: number;
  rowsPerPage: number;
  srchText?: string;
}) => {
  return useQuery(['embedSearch', page, srchText], () => embedSearchGet({ uuid, page, rowsPerPage, srchText }), {
    onSuccess: async () => {
      // do something
    },
    refetchOnMount: true,
    suspense: true,
    useErrorBoundary: true,
    enabled: !!uuid,
  });
};

const embedPreviewGet = async ({ qryType, qryString, pageParam = 1 }: { qryType: string; qryString: string; pageParam: number }) => {
  return instance.get(`/embed/config/preview`, { params: { type: qryType, qryString, page: pageParam } }).then((res) => res.data);
};

export const useEmbedPreviewGet = ({ qryType, qryString, pageParam = 1 }: { qryType: string; qryString: string; pageParam: number }) => {
  return useQuery(['embedPreview', qryType, qryString], () => embedPreviewGet({ qryType, qryString, pageParam }), {
    onSuccess: async () => {
      // do something
    },
    refetchOnMount: true,
    suspense: true,
    useErrorBoundary: true,
    enabled: !!qryType,
  });
};

const embedPut = ({ uuid, title, queryString, referrerDomain, template, type }: IEmbedId): Promise<IEmbedPostRes> => {
  return instance
    .put(`/embed/config/${uuid}`, {
      title,
      queryString,
      referrerDomain,
      template,
      type,
    })
    .then((res) => res.data);
};

export const useEmbedPut = (): UseMutationResult<IEmbedPostRes, AxiosError> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(embedPut, {
    onSuccess: async ({ success }: IEmbedPostRes) => {
      if (success) {
        await navigate(`/embed/config`, { replace: true });
        await queryClient.refetchQueries(['embedList']).then(() =>
          toast.success('임베드 수정 성공', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
          })
        );
      }
    },
  });
};

const embedDelete = ({ uuid }: { uuid: string }): Promise<any> => {
  return instance.delete(`/embed/config/${uuid}`).then((res) => res.data);
};

export const useEmbedDelete = (): UseMutationResult<any, AxiosError> => {
  const queryClient = useQueryClient();
  return useMutation(embedDelete, {
    onSuccess: async ({ success }: any) => {
      if (success) {
        await queryClient.refetchQueries(['embedList']).then(() =>
          toast.success('임베드 삭제 성공', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
          })
        );
      }
    },
  });
};
