import styled from 'styled-components';

import { colors, flexbox, textEllipsis } from './common';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const WidgetTitleBase = styled.p`
  ${textEllipsis({ n: 1 })};
  font-size: 14px;
  font-weight: 700;
  color: ${colors.PRIMARY};
`;
export const TitleBase = styled.p`
  ${textEllipsis({ n: 2 })};
  font-size: 14px;
  color: ${colors.GRAY2};
  line-height: 1.2;
`;

export const ButtonBase = styled.button`
  border-radius: 4px;
  line-height: 20px;

  svg {
    height: 12px;
  }
`;
export const SummaryBase = styled.p`
  ${textEllipsis({ n: 1 })};
  font-size: 12px;
  color: ${colors.GRAY5};
`;
export const RedPBase = styled.p`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: 700;
`;
export const GRAY7PBase = styled.p`
  color: ${colors.GRAY7};
  font-size: 12px;
`;

export const GRAYAPBase = styled.p`
  color: ${colors.GRAYA};
  font-size: 12px;
`;
export const BluePBase = styled.p`
  color: ${colors.BLUE};
  font-size: 12px;
`;
export const ImgVerticalWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  max-width: 200px;
  height: 120px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const ImgHorizontalWrapper = styled.div<{ bg: string }>`
  border-radius: 8px;
  width: 100%;
  height: 120px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    height: auto;
    object-position: center center;
  }
`;

export const FlexDiv = styled.div<{ jc?: string }>`
  ${({ jc }) => {
    return jc ? `${flexbox({ fd: 'row', jc })};` : `${flexbox({ fd: 'row', jc: 'space-between' })};`;
  }};
`;
