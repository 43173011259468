import { useParams, useSearchParams } from 'react-router-dom';
import { Suspense } from 'react';

import { useEmbedGet } from 'hooks/useEmbed';
import Loading from 'components/Loading';
import BlockPage from 'components/BlockPage';
import DataFetch from './dataFetch';

const IFrameEmbed = () => {
  const { uuid } = useParams() || {};
  const [searchParams] = useSearchParams();
  const view = Number(searchParams.get('cpp')) || 5;

  const {
    data: { config },
  } = useEmbedGet({ uuid });
  const { title, template, type, referrerDomain } = config;

  if (document.referrer.indexOf(referrerDomain) < 0) return <BlockPage />;
  if (!uuid || !config) return undefined;

  return (
    <Suspense fallback={<Loading />}>
      <DataFetch title={title} type={type} template={template} uuid={uuid} view={view} />
    </Suspense>
  );
};

export default IFrameEmbed;
