import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MouseEvent, useState } from 'react';

import { useEmbedDelete } from 'hooks/useEmbed';
import { useWidgetDelete } from 'hooks/useWidget';
import { IEmbedList } from 'types/embed';
import { IWidgetList } from 'types/widget';
import {
  FormName,
  isTWidgetOrTEmbed,
  isTWidgetTemplateOrTEmbedTemplate,
  TEmbed,
  TEmbedTemplate,
  TemplateName,
  TFormType,
  TWidget,
  TWidgetTemplate,
  WidgetName,
} from 'types/enum';

import { BigNav, Container, H1 } from 'styles/StyledComponent';
import { colors, flexbox } from 'styles/common';
import { StyledPageNav } from './StyledPageNav';
import { DeleteIcon } from 'assets/svgs';
import CommonViewSource from './CommonViewSource';

const CommonTable = ({ type, list, totalPage }: { type: TFormType; list: IEmbedList[] | IWidgetList[]; totalPage: number }) => {
  const { mutate: deleteWidget } = useWidgetDelete();
  const { mutate: deleteEmbed } = useEmbedDelete();

  const [currentItem, setCurrentItem] = useState<{ uuid: string; template: TWidgetTemplate | TEmbedTemplate; datatype: TWidget | TEmbed }>();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleModalOpen = (e: MouseEvent<HTMLButtonElement>) => {
    const {
      value,
      dataset: { template, datatype },
    } = e.currentTarget;
    if (isTWidgetTemplateOrTEmbedTemplate(template) && isTWidgetOrTEmbed(datatype)) {
      setOpen(true);
      setCurrentItem({ uuid: value, template, datatype });
    }
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      if (type === 'widget') deleteWidget({ uuid: e.currentTarget.value });
      if (type === 'embed') deleteEmbed({ uuid: e.currentTarget.value });
    }
  };

  return (
    <>
      <Container>
        <H1>{FormName[type]} 관리</H1>
        <div className='info-wrapper'>
          <p>
            {FormName[type]} 설정 갯수 : {list.length || 0} 개
          </p>
          {type === 'widget' && <BigNav to='/widget/config/reg'>새 설정 만들기</BigNav>}
          {type === 'embed' && <BigNav to='/embed/config/reg'>새 설정 만들기</BigNav>}
        </div>
        <div className='list-wrapper'>
          <Table className='result-table board'>
            <colgroup>
              <col width='10%' />
              {type === 'widget' ? <col width='35%' /> : <col width='45%' />}
              <col width='10%' />
              {type === 'widget' && <col width='10%' />}
              <col width='10%' />
              <col width='25%' />
            </colgroup>
            <thead>
              <tr>
                <th>관리번호</th>
                <th>{FormName[type]} 이름</th>
                {type === 'widget' && <th>타입</th>}
                <th>템플릿</th>
                <th>조회수</th>
                <th>기능</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item: IEmbedList | IWidgetList, index: number) => {
                const key = `list-${index}`;
                return (
                  <tr key={key}>
                    <td className='no'>{item.configUUID}</td>
                    <td className='title'>
                      <Link to={`/${type}/config/mod/${item.configUUID}`}>{item.title}</Link>
                    </td>
                    {type === 'widget' && <td>{WidgetName[item.type]}</td>}
                    <td>{TemplateName[item.template]}</td>
                    <td>{item.viewCnt}</td>
                    <FuncTd>
                      <Link to={`/${type}/config/reg/${item.configUUID}`}>설정복사</Link>
                      <button type='button' value={item.configUUID} data-datatype={item.type} data-template={item.template} onClick={handleModalOpen}>
                        소스보기
                      </button>
                      <button type='button' value={item.configUUID} onClick={handleDelete}>
                        <DeleteIcon />
                        삭제
                      </button>
                    </FuncTd>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <StyledPageNav totalPages={totalPage} />
      </Container>

      {/* 소스 보기 모달 */}
      <CommonViewSource pageType={type} currentItem={currentItem} isOpen={isOpen} handleModalClose={handleModalClose} />
    </>
  );
};

const FuncTd = styled.td`
  ${flexbox({ jc: 'center', gap: 5 })};
  flex-wrap: wrap;

  button,
  a {
    border-radius: 6px;
    padding: 0 8px;
    font-size: 14px;
    border: 1px solid ${colors.PRIMARY};
    background: ${colors.WHITE};
    line-height: 30px;

    svg {
      position: relative;
      top: 1px;
      height: 13px;
    }
  }

  a {
    &:nth-of-type(1) {
      background: ${colors.PRIMARY};
      color: ${colors.WHITE} !important;
    }
  }

  button {
    &:nth-of-type(1) {
      border-color: ${colors.PRIMARY};
      background: ${colors.WHITE};
      color: ${colors.PRIMARY};
    }

    &:nth-of-type(2) {
      color: ${colors.RED};
      border-color: ${colors.RED};
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;

  a,
  a:visited {
    color: ${colors.GRAY5};
  }

  thead {
    border-bottom: 1px solid ${colors.BLACK};

    th {
      padding: 10px;
      line-height: 1.5;
      color: #787878;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${colors.GRAY78};
    }
    td {
      padding: 8px;
      line-height: 1.5;

      &:nth-child(3) {
        color: ${colors.GRAY78};
      }
    }
  }
`;

export default CommonTable;
