import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useEmbedListGet } from 'hooks/useEmbed';
import CommonTable from 'components/CommonTable';

const Embed = () => {
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const pageNum = searchParams.get('p') || 1;
  const { data } = useEmbedListGet({ page: Number(pageNum) });
  const { list, totalCnt } = data || { list: [], totalCnt: 0 };

  const totalPage = Math.ceil(totalCnt / 10) === 0 ? 1 : Math.ceil(totalCnt / 10);

  useEffect(() => {
    queryClient.refetchQueries(['embedList', pageNum]);
  }, [pageNum, queryClient]);

  return <CommonTable type='embed' list={list} totalPage={totalPage} />;
};

export default Embed;
