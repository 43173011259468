import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { ISearchDate } from 'types/form';

import { commonInputStyle } from 'styles/common';

interface IProps {
  disabled: boolean;
  searchDate: ISearchDate;
  setSearchDate: Dispatch<SetStateAction<ISearchDate>>;
}

export const DatePicker = ({ disabled, searchDate, setSearchDate }: IProps) => {
  const today = dayjs().format('YYYY-MM-DD');

  const [firstDt, setFirstDt] = useState<string>(searchDate ? searchDate.searchDateFrom : today);
  const [secondDt, setSecondDt] = useState<string>(searchDate ? searchDate.searchDateTo : today);

  const handleFirst = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const before = dayjs(value);
    const after = dayjs(secondDt);
    if (before.isAfter(after)) {
      setFirstDt(secondDt);
      // eslint-disable-next-line no-alert
      alert('시작일은 종료일보다 빨라야합니다.');
    } else setFirstDt(value);
  };

  const handleSecond = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const before = dayjs(firstDt);
    const after = dayjs(value);
    if (before.isAfter(after)) {
      setSecondDt(firstDt);
      // eslint-disable-next-line no-alert
      alert('종료일은 시작일보다 늦어야합니다.');
    } else setSecondDt(value);
  };

  useEffect(() => {
    if (firstDt.length > 0 && secondDt.length > 0) setSearchDate({ searchDateFrom: firstDt, searchDateTo: secondDt });
  }, [firstDt, secondDt, setSearchDate]);

  return (
    <>
      <Input type='date' name='searchDateFrom' value={firstDt} onChange={handleFirst} disabled={disabled} required={!disabled} />
      <span> ~ </span>
      <Input type='date' name='searchDateTo' value={secondDt} onChange={handleSecond} disabled={disabled} required={!disabled} />
    </>
  );
};

const Input = styled.input`
  ${commonInputStyle()};
  width: 160px;
`;
