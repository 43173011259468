import { useParams } from 'react-router-dom';

import { useWidgetGet } from 'hooks/useWidget';
import CommonForm from 'components/CommonForm';

const WidgetModify = () => {
  const { uuid } = useParams();
  const {
    data: { config },
  } = useWidgetGet({ uuid });

  return <CommonForm pageType='widget' pageSubmit='수정' data={config} uuid={uuid} />;
};

export default WidgetModify;
