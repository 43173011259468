import { useEffect, useRef, cloneElement, DetailedHTMLProps, InputHTMLAttributes, ReactElement, useState, ChangeEvent } from 'react';
import styled from 'styled-components';

import { compareArray } from 'utils/compareArray';

import { colors, flexbox } from 'styles/common';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value: string | number;
  toggle?: boolean;
}

export const Checkbox = ({ name, children, value, ...props }: IProps) => {
  return (
    <>
      <input type='checkbox' name={name} id={`${name}-${value}`} value={value} {...props} />
      <label htmlFor={`${name}-${value}`}>{children || value}</label>
    </>
  );
};

export const StyledCheckboxGroup = ({
  name,
  children,
  required,
  defaultValues,
}: {
  name: string;
  children: ReactElement[];
  required?: boolean;
  defaultValues?: number[] | string[];
}) => {
  const [isToggleChecked, setToggleChecked] = useState<boolean>(false);
  const [checkItems, setCheckItems] = useState([]);

  const toggleRef = useRef<HTMLInputElement | null>(null);

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.checked) setCheckItems([]);
    setToggleChecked((prev) => !prev);
  };

  useEffect(() => {
    if (toggleRef && toggleRef.current) {
      if (isToggleChecked) {
        const withoutToggleValue = Array.from(children, (item) => item.props.value).filter((el) => el !== toggleRef.current.value);
        setCheckItems(withoutToggleValue);
      }
    }
  }, [isToggleChecked, children]);

  // children values에서 togglevalue의 값을 뺀 어레이가 checkitems랑 동일하다면 istogglechecked 선택
  useEffect(() => {
    if (toggleRef && toggleRef.current) {
      const withoutTogglevalue = Array.from(children, (item) => item.props.value).filter((el) => el !== toggleRef.current.value);
      if (compareArray(withoutTogglevalue, checkItems)) {
        setToggleChecked(true);
      }
    }
  }, [checkItems, children, defaultValues]);

  // defaultValues가 togglevalue라면 istogglechecked 선택
  useEffect(() => {
    if (defaultValues) {
      if (toggleRef && toggleRef.current) {
        if (compareArray([toggleRef.current.value], defaultValues)) setToggleChecked(true);
      }
      setCheckItems(defaultValues);
    }
  }, [defaultValues]);

  return (
    <Wrapper>
      <input className='hidden' type='text' name={name} value={checkItems} readOnly />

      {children.map((item, index) => {
        const key = `${item.props.value}-${index}`;
        const handleChange = () => {
          if (checkItems.includes(item.props.value)) {
            setCheckItems(checkItems.filter((el) => el !== item.props.value));
          } else setCheckItems((prev) => [...prev, item.props.value]);
          setToggleChecked(false);
        };

        const defaultProps = {
          name: `${name}-copy`,
          onChange: handleChange,
          checked: checkItems.includes(item.props.value),
          required: required && checkItems.length <= 0,
        };
        const babyWithProps = cloneElement(item, { ...defaultProps });

        return item.props.toggle ? (
          <Li key={key} className='bar'>
            <input
              ref={toggleRef}
              type='checkbox'
              id={`toggle-button-${name}`}
              value={item.props.value}
              checked={isToggleChecked}
              onChange={handleToggle}
            />
            <label htmlFor={`toggle-button-${name}`}>전체선택</label>
          </Li>
        ) : (
          <Li key={key}>{babyWithProps}</Li>
        );
      })}
    </Wrapper>
  );
};

const Li = styled.li`
  position: relative;

  input {
    position: absolute;
    z-index: -1;

    &:checked + label {
      color: ${colors.GRAY2};
      border-color: ${colors.GRAY2};
    }
  }

  input + label,
  button {
    font-size: 14px;
    display: block;
    line-height: 1;
    color: ${colors.GRAY7};
    background: ${colors.GRAYF2};
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    padding: 8px 12px 6px;
    border: 1px solid ${colors.GRAY9};
    border-radius: 16px;
    border-color: ${colors.GRAYA};
    color: ${colors.GRAYA};
    cursor: pointer;
  }
`;

const Wrapper = styled.ul`
  ${flexbox({ fd: 'row', jc: 'flex-start', gap: 10 })};
  flex-wrap: wrap;

  li.bar {
    margin-right: 10px;

    &::after {
      position: absolute;
      content: '';
      top: 3px;
      right: -10px;
      width: 1px;
      height: 22px;
      background-color: ${colors.GRAYC};
    }
  }
`;

StyledCheckboxGroup.Checkbox = Checkbox;
