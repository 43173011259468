import React, { ChangeEvent, useCallback, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { colors, commonLabelStyle, flexbox } from 'styles/common';
import { CloseIcon } from 'assets/svgs';

interface IFileTypes {
  id: number;
  object: File;
}

interface IProps {
  label?: string;
}

const StyledUploadfile = ({ label }: IProps) => {
  const [, setIsDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<IFileTypes[]>([]);

  const dragRef = useRef<HTMLLabelElement | null>(null);
  const fileId = useRef<number>(0);

  const onChangeFiles = useCallback(
    (e: ChangeEvent<HTMLInputElement> | any): void => {
      let selectFiles: File[];
      let tempFiles: IFileTypes[] = files;

      if (e.type === 'drop') {
        selectFiles = e.dataTransfer.files;
      } else {
        selectFiles = e.target.files;
      }

      for (const file of selectFiles) {
        tempFiles = [
          ...tempFiles,
          {
            // eslint-disable-next-line no-plusplus
            id: fileId.current++,
            object: file,
          },
        ];
      }

      setFiles(tempFiles);
    },
    [files]
  );

  const handleFilterFile = useCallback(
    (id: number): void => {
      setFiles(files.filter((file: IFileTypes) => file.id !== id));
    },
    [files]
  );

  const handleDragIn = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer!.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles]
  );

  const initDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener('dragenter', handleDragIn);
      dragRef.current.addEventListener('dragleave', handleDragOut);
      dragRef.current.addEventListener('dragover', handleDragOver);
      dragRef.current.addEventListener('drop', handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener('dragenter', handleDragIn);
      dragRef.current.removeEventListener('dragleave', handleDragOut);
      dragRef.current.removeEventListener('dragover', handleDragOver);
      dragRef.current.removeEventListener('drop', handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();

    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  return (
    <>
      {label && <Label>{label}</Label>}

      <Wrapper>
        <input className='hidden' type='file' id='fileUpload' onChange={onChangeFiles} multiple />
        <label htmlFor='fileUpload' ref={dragRef}>
          파일 업로드 (여기에 파일을 내려놓으세요.)
        </label>

        <div className='filelist'>
          {files.length > 0 &&
            files.map((file: IFileTypes) => {
              const {
                id,
                object: { name },
              } = file;

              return (
                <button type='button' className='file' key={id} onClick={() => handleFilterFile(id)}>
                  {name} <CloseIcon />
                </button>
              );
            })}
        </div>
      </Wrapper>
    </>
  );
};

const Label = styled.p`
  ${commonLabelStyle};
`;

const Wrapper = styled.div`
  position: relative;

  label {
    display: inline-block;
    width: 100%;
    line-height: 200px;
    color: ${colors.GRAYA};
    border: 1px solid ${colors.GRAYA};
    border-radius: 4px;
  }

  .filelist {
    text-align: left;
    position: absolute;
    top: 30px;
    left: 10px;
  }

  .file {
    ${flexbox({ jc: 'flex-start', ai: 'center', gap: 10 })};

    svg {
      position: relative;
      top: 3px;
      margin-left: 3px;
      height: 15px;
      fill: ${colors.GRAY9};
    }

    &:hover {
      color: ${colors.RED};
      svg {
        fill: ${colors.RED};
      }
    }
  }
`;

export default StyledUploadfile;
