import styled from 'styled-components';

import errorImage from 'assets/images/errorpage-image.webp';
import { colors, flexbox } from 'styles/common';

const BlockPage = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <img src={errorImage} alt='error' width={240} height={181} />
        <h1>
          이 도메인에서는 위젯 서비스를 사용하실 수 없습니다.
          <br />
          위젯 관리에서 대상 도메인을 확인하세요.
        </h1>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${flexbox({ ai: 'center', jc: 'center' })}
  width: 100vw;
  height: 100vh;
`;

const InnerWrapper = styled.div`
  ${flexbox({ ai: 'center', gap: 20 })}
  padding: 30px;
  flex-wrap: wrap;
  background-color: ${colors.WHITE};
  text-align: center;

  h1 {
    font-size: clamp(1.5rem, 2.5vw, 4rem);
    font-weight: 500;
    line-height: 1.618;
    color: ${colors.GRAY4};
  }
`;

export default BlockPage;
