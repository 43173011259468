import styled from 'styled-components';

import { useUserGet, useUserLoginPost } from 'hooks/useUser';
import StyledInput from 'components/StyledInput';

import { colors } from 'styles/common';
import { BigButton, H1 } from 'styles/StyledComponent';

const Login = () => {
  const { mutate } = useUserLoginPost();
  useUserGet();

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    mutate(formJson);
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <H1>관리자 로그인</H1>
        <StyledInput name='account' placeholder='아이디를 입력하세요.' />
        <StyledInput name='password' type='password' placeholder='비밀번호를 입력하세요.' />
        <BigButton type='submit'>login</BigButton>
        {/* <NavLink to='/register' replace> */}
        {/*   등록 요청 */}
        {/* </NavLink> */}
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const Form = styled.form`
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  > a {
    font-size: 13px;
    color: ${colors.GRAY9};
  }

  > div {
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 50px;
    width: 100%;
  }
`;

export default Login;
