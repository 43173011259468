import { useEffect, useState } from 'react';

import { useWidgetPost, useWidgetPut } from 'hooks/useWidget';
import { useEmbedPost, useEmbedPut } from 'hooks/useEmbed';
import { parseQrystring, structQryText } from 'utils/structQryText';
import { IForm, IQuery, ISearchDate } from 'types/form';
import { EmbedName, FormName, TFormType, TSubmit, WidgetName } from 'types/enum';
import { IWidgetList } from 'types/widget';
import { IEmbedList } from 'types/embed';
import StyledInput, { Label } from 'components/StyledInput';
import { StyledSelect } from 'components/StyledDropdown';
import { StyledCheckboxGroup } from 'components/StyledCheckbox';
import { DatePicker } from 'components/DatePicker';
import CommonPreview from 'components/CommonPreview';

import { CloseIcon } from 'assets/svgs';
import { BigButton, BigButtonLine, BigNavRed, FlexDiv, H1, RegisterUl, RegisterWrapper } from 'styles/StyledComponent';
import { Tooltip } from './Tooltip';

const CommonForm = ({
  pageType,
  pageSubmit,
  data,
  uuid,
}: {
  pageType: TFormType;
  pageSubmit: TSubmit;
  data?: IEmbedList | IWidgetList;
  uuid?: string;
}) => {
  const { mutate: embedPost } = useEmbedPost();
  const { mutate: embedPut } = useEmbedPut();
  const { mutate: widgetPost } = useWidgetPost();
  const { mutate: widgetPut } = useWidgetPut();

  // const { title: formTitle, referrerDomain, template, type, queryString } = data || {};
  const { queryString } = data || {};

  const [title, setTitle] = useState<string>();
  const [domain, setDomain] = useState<string>();
  const [template, setTemplate] = useState<string>();
  const [type, setType] = useState<string>();

  const [visible, setVisible] = useState<boolean>(false);
  const [isDateRangeChecked, setDateRangeChecked] = useState<boolean>(false);
  const [includeKeyword, setIncludeKeyword] = useState<string>();
  const [excludeKeyword, setExcludeKeyword] = useState<string>();

  const [elecnum, setElecnum] = useState<string[]>();
  const [opt0, setOpt0] = useState<string[]>();
  const [opt1, setOpt1] = useState<string[]>();
  const [opt2, setOpt2] = useState<string[]>();
  const [opt3, setOpt3] = useState<string[]>();
  const [searchDate, setSearchDate] = useState<ISearchDate>();
  const [includeKeywords, setIncludeKeywords] = useState<string[]>([]);
  const [excludeKeywords, setExcludeKeywords] = useState<string[]>([]);

  const [isOpen, setOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<IQuery | null>();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form) as unknown as Iterable<[IForm, FormDataEntryValue]>;
    const formJson: IForm = Object.fromEntries(formData);

    const queries = structQryText({ ...formJson, includeKeywords, excludeKeywords }) || '';
    const query: IQuery = {
      title: formJson.title,
      type: formJson.type,
      template: formJson.template.length < 2 ? 'top' : formJson.template,
      referrerDomain: formJson.referrerDomain,
      queryString: queries,
    };

    if (e.nativeEvent.submitter.name === 'post') {
      if (pageType === 'widget') {
        if (pageSubmit === '수정') await widgetPut({ uuid, ...query });
        else await widgetPost(query);
      }
      if (pageType === 'embed') {
        if (pageSubmit === '수정') await embedPut({ uuid, ...query });
        else await embedPost(query);
      }
    }
    if (e.nativeEvent.submitter.name === 'preview') {
      await setModalData(query);
      await setOpen(true);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setModalData(null);
  };

  useEffect(() => {
    if (queryString && queryString.length > 0) {
      const {
        elecnum: e,
        opt0: o0,
        opt1: o1,
        opt2: o2,
        opt3: o3,
        searchDateFrom: sf,
        searchDateTo: st,
        excludeKeywords: ek,
        includeKeywords: ik,
      } = parseQrystring(queryString);
      setElecnum(e);
      setOpt0(o0);
      setOpt1(o1);
      setOpt2(o2);
      setOpt3(o3);
      setDateRangeChecked(!!sf && !!st);
      setSearchDate({ searchDateFrom: sf, searchDateTo: st });
      setIncludeKeywords(ik);
      setExcludeKeywords(ek);
    }
  }, [queryString]);

  useEffect(() => {
    if (data) {
      const { title: t, referrerDomain: r, template: tm, type: tp } = data;
      setTitle(t);
      setDomain(r);
      setTemplate(tm);
      setType(tp);
    } else {
      if (pageType === 'widget') {
        setType('news');
      }
      if (pageType === 'embed') {
        setType('bill');
      }
    }
  }, [data, pageType]);

  useEffect(() => {
    if (type === 'news' || type === 'business' || type === 'service' || type === 'lawnum') setVisible(false);
    if (type === 'bill' || type === 'asmman') setVisible(true);
  }, [type, pageType]);

  const handleChange = (e) => {
    setType(e.currentTarget.value);
  };

  return (
    <>
      <RegisterWrapper>
        <H1>
          {FormName[pageType]}
          {pageSubmit}
        </H1>
        <div className='list-wrapper'>
          <form id='register' onSubmit={handleSubmit}>
            <div className='row'>
              <StyledInput
                name='title'
                label={`${FormName[pageType]} 제목`}
                placeholder={`${FormName[pageType]} 제목`}
                maxLength={15}
                required
                value={title || ''}
                onChange={(e) => setTitle(e.currentTarget.value)}
              />
              <StyledInput
                name='referrerDomain'
                label='대상 도메인'
                placeholder='https://example.com'
                required
                value={domain || ''}
                onChange={(e) => setDomain(e.currentTarget.value)}
              />
              {pageType === 'embed' && (
                <StyledSelect name='template' label='템플릿' value={template} setValue={setTemplate}>
                  <StyledSelect.Option value='template1'>template1</StyledSelect.Option>
                  <StyledSelect.Option value='template2'>template2</StyledSelect.Option>
                </StyledSelect>
              )}
              {pageType === 'widget' && (
                <StyledSelect name='template' label='템플릿' value={template} setValue={setTemplate}>
                  <StyledSelect.Option value='top'>상단</StyledSelect.Option>
                  <StyledSelect.Option value='side'>측면</StyledSelect.Option>
                  <StyledSelect.Option value='center'>중앙</StyledSelect.Option>
                  {/* <StyledSelect.Option value='full'>전체화면</StyledSelect.Option> */}
                </StyledSelect>
              )}
            </div>
            <div className='row'>
              <Label className='label'>{FormName[pageType]} 타입</Label>
              {pageType === 'embed' && (
                <FlexDiv>
                  <div>
                    <input type='radio' id='type-1' name='type' value='bill' checked={type === 'bill'} onChange={handleChange} />
                    <label htmlFor='type-1'>{EmbedName.bill}</label>
                  </div>
                  <div>
                    <input type='radio' id='type-2' name='type' value='asmman' checked={type === 'asmman'} onChange={handleChange} />
                    <label htmlFor='type-2'>{EmbedName.asmman}</label>
                  </div>
                  <div>
                    <input type='radio' id='type-0' name='type' value='lawnum' checked={type === 'lawnum'} onChange={handleChange} />
                    <label htmlFor='type-0'>{EmbedName.lawnum}</label>
                  </div>
                  <div>
                    <input type='radio' id='type-3' name='type' value='news' checked={type === 'news'} onChange={handleChange} />
                    <label htmlFor='type-3'>{EmbedName.news}</label>
                  </div>
                </FlexDiv>
              )}
              {pageType === 'widget' && (
                <FlexDiv>
                  <div>
                    <input type='radio' id='type-0' name='type' value='news' checked={type === 'news'} onChange={handleChange} />
                    <label htmlFor='type-0'>{WidgetName.news}</label>
                  </div>
                  <div>
                    <input type='radio' id='type-1' name='type' value='business' checked={type === 'business'} onChange={handleChange} />
                    <label htmlFor='type-1'>{WidgetName.business}</label>
                  </div>
                  <div>
                    <input type='radio' id='type-2' name='type' value='service' checked={type === 'service'} onChange={handleChange} />
                    <label htmlFor='type-2'>{WidgetName.service}</label>
                  </div>
                  <div>
                    <input type='radio' id='type-3' name='type' value='bill' checked={type === 'bill'} onChange={handleChange} />
                    <label htmlFor='type-3'>{WidgetName.bill}</label>
                  </div>
                </FlexDiv>
              )}
            </div>
            <RegisterUl>
              <Label className='label'>검색 옵션 설정</Label>
              {visible && (
                <>
                  <li>
                    <p className='title'>임기 *</p>
                    <StyledCheckboxGroup required name='elecnum' defaultValues={elecnum || ['21', '20']}>
                      <StyledCheckboxGroup.Checkbox value='21'>21대</StyledCheckboxGroup.Checkbox>
                      <StyledCheckboxGroup.Checkbox value='20'>20대</StyledCheckboxGroup.Checkbox>
                      <StyledCheckboxGroup.Checkbox value='19'>19대</StyledCheckboxGroup.Checkbox>
                      <StyledCheckboxGroup.Checkbox value='18'>18대</StyledCheckboxGroup.Checkbox>
                    </StyledCheckboxGroup>
                  </li>
                  <li>
                    <p className='title'>검색</p>
                    <StyledCheckboxGroup name='opt0' defaultValues={opt0 || ['registered']}>
                      <StyledCheckboxGroup.Checkbox value='noticeOnly'>입법예고만</StyledCheckboxGroup.Checkbox>
                      <StyledCheckboxGroup.Checkbox value='altOnly'>대안입법만</StyledCheckboxGroup.Checkbox>
                      <StyledCheckboxGroup.Checkbox value='registered'>원문등록</StyledCheckboxGroup.Checkbox>
                    </StyledCheckboxGroup>
                  </li>
                  <li>
                    <p className='title'>입법종류</p>
                    <div>
                      <StyledCheckboxGroup name='opt1' defaultValues={opt1 || ['99']}>
                        <StyledCheckboxGroup.Checkbox value='99' toggle>
                          전체
                        </StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='1'>일부</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='2'>전부</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='4'>폐지</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='8'>제정</StyledCheckboxGroup.Checkbox>
                      </StyledCheckboxGroup>
                    </div>
                  </li>
                  <li>
                    <p className='title'>계류의안</p>
                    <div>
                      <StyledCheckboxGroup name='opt2' defaultValues={opt2 || ['1']}>
                        <StyledCheckboxGroup.Checkbox value='1' toggle>
                          전체
                        </StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='2'>접수</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='4'>소관위접수</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='8'>소관위심사</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='16'>체계자구심사</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='32'>본회의부위</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='64'>본회의불부의</StyledCheckboxGroup.Checkbox>
                      </StyledCheckboxGroup>
                    </div>
                  </li>
                  <li>
                    <p className='title'>처리의안</p>
                    <div>
                      <StyledCheckboxGroup name='opt3' defaultValues={opt3 || ['1']}>
                        <StyledCheckboxGroup.Checkbox value='1' toggle>
                          전체
                        </StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='2'>원안가결</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='4'>수정가결</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='8'>대안반영</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='16'>철회</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='32'>부결</StyledCheckboxGroup.Checkbox>
                        <StyledCheckboxGroup.Checkbox value='64'>폐기</StyledCheckboxGroup.Checkbox>
                      </StyledCheckboxGroup>
                    </div>
                  </li>

                  <li>
                    <p className='title'>검색기간</p>
                    <DatePicker disabled={!isDateRangeChecked} searchDate={searchDate} setSearchDate={setSearchDate} />
                    <div>
                      <input
                        type='checkbox'
                        id='dateRange'
                        onChange={() => setDateRangeChecked((prev) => !prev)}
                        defaultChecked={isDateRangeChecked}
                      />
                      <label htmlFor='dateRange'>적용</label>
                    </div>
                  </li>
                </>
              )}
              <li>
                {type === 'lawnum' && !visible ? (
                  <>
                    <p className='title'>의안번호 *</p>
                    <Tooltip content='키워드1,키워드2 는 &#39;OR&#39;조건으로 검색식이 생성되며, 등록 후 새로운 창에 키워드3, 키워드4 를 입력하면 &#39;AND&#39;조건으로 검색식이 생성됩니다. (예시: (키워드1 or 키워드2) and (키워드3 or 키워드4))' />
                  </>
                ) : (
                  <>
                    <p className='title'>포함 검색어</p>
                    <Tooltip content='키워드1,키워드2 는 &#39;OR&#39;조건으로 검색식이 생성되며, 등록 후 새로운 창에 키워드3, 키워드4 를 입력하면 &#39;AND&#39;조건으로 검색식이 생성됩니다. (예시: (키워드1 or 키워드2) and (키워드3 or 키워드4))' />
                  </>
                )}
                <FlexDiv fd='column'>
                  <div className='row'>
                    <input
                      className='hide'
                      type='text'
                      value={includeKeywords || ''}
                      name='includeKeywords'
                      onChange={() => {}}
                      required={pageType === 'embed' && !visible && includeKeywords.length < 1}
                    />
                    <StyledInput
                      className='keyword'
                      value={includeKeyword || ''}
                      onChange={(e) => setIncludeKeyword(e.currentTarget.value)}
                      placeholder={type === 'lawnum' && !visible ? '검색할 의안번호를 입력하세요.' : '(예시) 키워드1,키워드2,키워드3'}
                      disabled={includeKeywords.length > 9}
                    />
                    <BigButton
                      type='button'
                      onClick={() => {
                        const splitArray = includeKeywords.flatMap((item) => item.split(',')).map((item) => item.trim());
                        if (splitArray.findIndex((i) => i === includeKeyword.trim()) < 0 && includeKeyword && includeKeyword.length > 0) {
                          setIncludeKeywords((prev) => [...prev, includeKeyword]);
                          setIncludeKeyword('');
                        }
                      }}
                    >
                      등록
                    </BigButton>
                  </div>
                  {includeKeywords.length > 0 && (
                    <FlexDiv wrap='wrap' gap={10}>
                      {includeKeywords.map((item, index) => {
                        const key = `includeKeyword-${index}`;
                        return (
                          <button
                            key={key}
                            type='button'
                            className='cancel'
                            onClick={() => setIncludeKeywords(includeKeywords.filter((el) => el !== item))}
                          >
                            {item}
                            <CloseIcon />
                          </button>
                        );
                      })}
                    </FlexDiv>
                  )}
                </FlexDiv>
              </li>
              <li>
                {type === 'lawnum' && !visible ? (
                  <>
                    <p className='title'>제외 의안번호</p>
                    <Tooltip content='키워드5, 키워드6 등으로 입력시 해당 키워드는 &#39;NOT&#39; 조건으로 검색식에서 제외됩니다.' />
                  </>
                ) : (
                  <>
                    <p className='title'>제외 검색어</p>
                    <Tooltip content='키워드5, 키워드6 등으로 입력시 해당 키워드는 &#39;NOT&#39; 조건으로 검색식에서 제외됩니다.' />
                  </>
                )}

                <FlexDiv fd='column'>
                  <div className='row'>
                    <input className='hidden' type='text' value={excludeKeywords || ''} name='excludeKeyords' readOnly />
                    <StyledInput
                      className='keyword'
                      value={excludeKeyword || ''}
                      onChange={(e) => setExcludeKeyword(e.currentTarget.value)}
                      placeholder={type === 'lawnum' && !visible ? '제외할 의안번호를 입력하세요.' : '(예시) 키워드5,키워드6,키워드7'}
                      disabled={excludeKeywords.length > 0}
                    />
                    <BigButton
                      type='button'
                      onClick={() => {
                        if (excludeKeyword && excludeKeyword.length > 0) {
                          setExcludeKeywords((prev) => [...prev, excludeKeyword]);
                          setExcludeKeyword('');
                        }
                      }}
                    >
                      등록
                    </BigButton>
                  </div>
                  {excludeKeywords.length > 0 && (
                    <FlexDiv wrap='wrap' gap={10}>
                      {excludeKeywords.map((item, index) => {
                        const key = `excludeKeyword-${index}`;
                        return (
                          <button
                            key={key}
                            type='button'
                            className='cancel'
                            onClick={() => setExcludeKeywords(excludeKeywords.filter((el) => el !== item))}
                          >
                            {item}
                            <CloseIcon />
                          </button>
                        );
                      })}
                    </FlexDiv>
                  )}
                </FlexDiv>
              </li>
            </RegisterUl>
          </form>
        </div>
        <div className='last'>
          <BigButton type='submit' form='register' name='post'>
            {pageSubmit}
          </BigButton>
          <BigButtonLine type='submit' form='register' name='preview'>
            미리보기
          </BigButtonLine>
          <BigNavRed to={`/${pageType}/config`}>취소</BigNavRed>
        </div>
      </RegisterWrapper>

      {/* 소스 보기 모달 */}
      <CommonPreview pageType={pageType} isOpen={isOpen} handleModalClose={handleModalClose} formData={modalData} />
    </>
  );
};

export default CommonForm;
