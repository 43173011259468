import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { useEmbedSearchGet } from 'hooks/useEmbed';
import { plainInstance } from 'hooks/worker/axios';

import BlockPage from 'components/BlockPage';
import AssmanTable from './AsmmanTable';
import BillTable from './BillTable';
import NewsTable from './NewsTable';

const DataFetch = ({ title, type, template, uuid, view }: { title: string; type: string; template: string; uuid: string; view: number }) => {
  const [searchParams] = useSearchParams();
  const pageNum = Number(searchParams.get('p')) || 1;
  const [srchText, setSrchText] = useState<string>();

  const {
    data: { data: searchData },
    isError,
  } = useEmbedSearchGet({ uuid, page: pageNum, rowsPerPage: view, srchText });

  const getData = async ({ id, cpp = 10, pageParam = 1 }: { id: string; cpp: number; pageParam: number }) => {
    const res = await plainInstance.get(`/embed/search/${id}?rowsPerPage=${cpp}&page=${pageParam}`);
    const {
      data: {
        data: { list },
        success,
      },
    } = res;
    if (success) {
      return { cursor: pageParam, list };
    }
    return {};
  };

  const { data, fetchNextPage } = useInfiniteQuery(['page', uuid], ({ pageParam = 1 }) => getData({ id: uuid, cpp: Number(view), pageParam }), {
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => lastPage.cursor + 1,
    enabled: type === 'news',
  });

  if (!searchData || isError) return <BlockPage />;

  const { billList, asmList, totcnt } = searchData || {};

  return (
    <>
      {type === 'news' && <NewsTable pages={data?.pages} template={template} fetchNextPage={fetchNextPage} />}
      {type === 'asmman' && <AssmanTable title={title} list={asmList} template={template} />}
      {(type === 'lawnum' || type === 'bill') && (
        <BillTable
          title={title}
          type={type}
          view={view}
          list={billList}
          template={template}
          totcnt={totcnt}
          srchText={srchText}
          setSrchText={setSrchText}
        />
      )}
    </>
  );
};

export default DataFetch;
